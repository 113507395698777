export const getLocalDateFromDateString = (dateString) => {
  if (!dateString) {
    return null;
  }

  if (typeof dateString !== "string" && dateString !== null) {
    console.error("Date string must be a string");
    return null;
  }

  const parsedDateString = dateString.split("T")[0];

  const day = parsedDateString.split("-")[2];
  const month = parsedDateString.split("-")[1];
  const year = parsedDateString.split("-")[0];

  const localJsDate = new Date(
    parseInt(year),
    parseInt(month) - 1,
    parseInt(day)
  );

  return localJsDate;
};

import axios from "src/utils/axios";

export const createCampaign = async ({
  name,
  message,
  batchSize,
  filterId,
}) => {
  try {
    const newCampaign = await axios.post("/api/campaigns", {
      name,
      message,
      batchSize,
      filterId,
    });

    return newCampaign;
  } catch (error) {
    console.error("Error => CreateCampaign");
    throw new Error("Error creating campaign");
  }
};

import PropTypes from "prop-types";
import { Typography, Avatar, Tooltip, Stack, Box, Chip } from "@mui/material";
import Profile from "./Profile";
import ThreadFlagMultiSelect from "src/components/common/ThreadFlagMultiSelect";
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  format,
} from "date-fns";

const getLastMessageTime = (createdAt) => {
  const now = new Date();
  const messageDate = new Date(createdAt);
  const secondsAgo = differenceInSeconds(now, messageDate);
  const minutesAgo = differenceInMinutes(now, messageDate);
  const hoursAgo = differenceInHours(now, messageDate);
  const daysAgo = differenceInDays(now, messageDate);

  let lastMessageTime;
  switch (true) {
    case secondsAgo < 60:
      lastMessageTime = `${secondsAgo}s ago`;
      break;
    case minutesAgo < 60:
      lastMessageTime = `${minutesAgo}m ago`;
      break;
    case hoursAgo < 24:
      lastMessageTime = `${hoursAgo}h ago`;
      break;
    case daysAgo > 0:
      lastMessageTime = `${daysAgo}d ago`;
      break;
    default:
      lastMessageTime = format(messageDate, "MM/dd/yyyy");
  }

  return lastMessageTime;
};

const ActiveChip = ({ label }) => {
  return (
    <Chip
      sx={{
        fontSize: 10,
        backgroundColor: label === "INACTIVE" ? "error.main" : "info.light",
        fontWeight: 500,
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textTransform: "uppercase",
        ellipsis: true,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}
      size="small"
      label={label}
    />
  );
};

const UnreadIndicator = () => {
  return (
    <Box
      sx={{
        backgroundColor: "error.main",
        width: 4,
        height: "100%",
        fontSize: 16,
        position: "absolute",
        left: 0,
        top: "50%",
        transform: "translateY(-50%)",
      }}
    />
  );
};

const ThreadItem = ({
  isSelected = false,
  isActive = true,
  id = null,
  hasUnreadClientMessageIds = false,
  displayName = "",
  lastMessage = null,
  agent = null,
  threadFlags,
  handleClick,
}) => {
  return (
    <Box
      sx={{
        color: (theme) =>
          isSelected ? theme.palette.primary.contrastText : "text.primary",
        height: "4.75rem",
        position: "relative",
        backgroundColor: (theme) =>
          isSelected ? theme.palette.primary.main : "background.default",
        cursor: "pointer",
        px: 1,
        py: 0.5,
        transition: "background-color .3s ease",
        "&:hover": {
          backgroundColor: (theme) =>
            isSelected ? theme.palette.primary.light : theme.palette.grey[200],
        },
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        cursor: "pointer",
      }}
      onClick={() =>
        handleClick && handleClick({ clientId: id, hasUnreadClientMessageIds })
      }
    >
      {hasUnreadClientMessageIds && <UnreadIndicator />}
      <Stack>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          sx={{ flex: 1, minWidth: 0 }}
        >
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ flex: 1, minWidth: 0 }}
          >
            <Typography
              variant="body2"
              sx={{
                fontWeight: 400,
                maxWidth: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {displayName}
            </Typography>
            {!isActive && <ActiveChip label="INACTIVE" />}
          </Stack>
          <Typography
            variant="caption"
            sx={{
              color: (theme) =>
                isSelected
                  ? theme.palette.primary.contrastText
                  : "text.secondary",
            }}
          >
            {getLastMessageTime(lastMessage.createdAt)}
          </Typography>
        </Stack>
        <Typography
          variant="caption"
          sx={{
            color: "text.secondary",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            color: (theme) =>
              isSelected
                ? theme.palette.primary.contrastText
                : theme.palette.grey[800],
          }}
        >
          {lastMessage.body}
        </Typography>

        <Stack direction="row" spacing={1} justifyContent="space-between">
          <ThreadFlagMultiSelect
            clientId={JSON.stringify(id)}
            clientThreadFlags={threadFlags}
            isWhite={isSelected}
          />
          <Stack direction="row" spacing={0.5} alignItems="center">
            {agent && (
              <Tooltip
                title={`${agent.firstName} ${agent.lastName}`}
                placement="top-end"
              >
                <Avatar
                  alt=""
                  src={agent.avatarLink}
                  size="small"
                  variant="circular"
                  sx={{
                    height: 24,
                    width: 24,
                    fontSize: 14,
                    color: (theme) =>
                      isSelected
                        ? theme.palette.primary.main
                        : theme.palette.grey[800],
                    backgroundColor: (theme) =>
                      isSelected ? "white" : theme.palette.grey[400],
                  }}
                >
                  {agent?.firstName?.[0]}
                </Avatar>
              </Tooltip>
            )}
            <Profile id={id} isWhite={isSelected} />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

ThreadItem.propTypes = {
  active: PropTypes.bool,
  isActive: PropTypes.bool,
  id: PropTypes.number,
  hasUnreadClientMessageIds: PropTypes.bool,
  displayName: PropTypes.string,
  lastMessage: PropTypes.object,
  agent: PropTypes.object,
};

export default ThreadItem;

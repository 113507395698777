import axios from "src/utils/axios";
import { toast } from "react-toastify";

export const getCampaignFilters = async ({ page = 0 }) => {
  try {
    const { data } = await axios.get("/api/campaigns/filters", {
      params: {
        page,
      },
    });

    return data;
  } catch (error) {
    toast.error("Error fetching campaign filters");
    return null;
  }
};

import { useEffect, useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Button, Card, CardContent, CardHeader, Divider } from "@mui/material";

import { useSocketEvent } from "src/utils/socket";
import { SOCKET_EVENTS } from "src/utils/constants";
import { keys } from "src/utils/react-query/key-factories";
import { getCampaignFilters, deleteCampaignFilter } from "src/api";

import ConfirmationModal from "src/components/common/ConfirmationModal";
import CampaignFilterFormModal from "./FormModal";
import Filter from "./Filter";
import ScrollBar from "src/components/common/ScrollBar";
import { Plus } from "react-feather";
const CampaignFilter = () => {
  const [deleteId, setDeleteId] = useState(null);
  const [filterModalData, setFilterModalData] = useState(null);
  const [campaignFilters, setCampaignFilters] = useState(null);

  const {
    error,
    data: filtersData,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery({
    retry: 1,
    queryKey: [keys.campaignFilters],
    queryFn: ({ pageParam }) =>
      getCampaignFilters({
        page: pageParam,
      }),
    getNextPageParam: (lastPage) => {
      return lastPage?.pagination?.nextPage;
    },
    refetchOnWindowFocus: false,
    enabled: true,
    initialPageParam: 0,
  });

  const handleCloseCampaignFilterModal = () => {
    setFilterModalData(null);
  };

  const handleDeleteConfirmation = async () => {
    try {
      await deleteCampaignFilter(deleteId);
      setDeleteId(null);
      refetch();
    } catch (error) {
      console.error(error);
      toast.error("Error deleting custom Campaign Filter");
    }
  };

  useSocketEvent({
    eventName: SOCKET_EVENTS.UPDATE_CAMPAIGN_FILTERS,
    onEvent: () => {
      refetch();
    },
  });

  useEffect(() => {
    if (!filtersData && isLoading && isFetching) return;

    const campaignFilters =
      filtersData?.pages?.map((page) => page?.filters).flat() || [];

    setCampaignFilters(campaignFilters);
  }, [filtersData, isLoading, isFetching]);

  return (
    <>
      <Card>
        <CardHeader
          title="Campaign Filters"
          action={
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => setFilterModalData({})}
              startIcon={<Plus size={16} />}
            >
              Create
            </Button>
          }
        />
        <Divider />
        <CardContent
          sx={{
            height: 500,
          }}
        >
          <ScrollBar
            id={keys.campaignFilters}
            fetchNextPage={fetchNextPage}
            hasMore={hasNextPage}
            isLoading={isFetching || isLoading}
            isInitialLoading={isLoading}
            currentPage={filtersData?.pageParams || 0}
            hasError={Boolean(error)}
            error={error}
          >
            {campaignFilters?.map((filter) => (
              <Filter
                key={filter.id}
                filter={filter}
                handleEdit={() => setFilterModalData(filter)}
                handleDelete={() => setDeleteId(filter.id)}
              />
            ))}
          </ScrollBar>
        </CardContent>
      </Card>
      <CampaignFilterFormModal
        isOpen={Boolean(filterModalData)}
        handleClose={handleCloseCampaignFilterModal}
        initialFormValues={filterModalData}
      />
      <ConfirmationModal
        content="Are you sure you want to delete this Campaign Filter?"
        ctaText="Delete"
        isModalOpen={Boolean(deleteId)}
        handleConfirmation={handleDeleteConfirmation}
        handleSetConfirmationModal={() => setDeleteId(null)}
      />
    </>
  );
};

export default CampaignFilter;

import axios from "src/utils/axios";
import { toast } from "react-toastify";

export const sendMessage = async ({ body, clientId, agentId }) => {
  try {
    await axios.post("/api/messages/out", {
      body,
      clientId,
      agentId,
    });
  } catch (error) {
    toast.error(`Error sending message`);
  }
};

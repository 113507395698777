import { useRef, forwardRef } from "react";
import { InputAdornment, Box } from "@mui/material";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";

const StyledTextareaAutosize = styled(BaseTextareaAutosize)(
  ({ theme, error }) => `
  box-sizing: border-box;
  width: 320px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.grey[900]};
  background: ${theme.palette.grey[50]};
  border: 1px solid ${
    error ? theme.palette.error.main : theme.palette.grey[200]
  };
  box-shadow: 0 2px 2px ${theme.palette.grey[50]};
  width: 100%;
  resize: none;
  &:hover {
    border-color: ${theme.palette.primary.main};
  }

  &:focus {
    border-color:  ${theme.palette.primary.main};
    box-shadow: 0 0 0 1px ${theme.palette.primary.main};
  }

  /* firefox */
  &:focus-visible {
    outline: 0;
  }

  &:disabled {
    color: ${theme.palette.grey[500]};
    cursor: not-allowed;
  }
`
);

const TextareaAutosize = forwardRef(
  (
    {
      value,
      handleChange,
      handleKeyDown,
      placeholder,
      disabled,
      startAdornment,
      name,
      onFocus,
      minRows,
      maxRows,
      error,
    },
    ref
  ) => {
    return (
      <Box position="relative">
        <InputAdornment
          position="start"
          sx={{
            position: "absolute",
            left: 16,
            top: 20,
            transform: "translateY(-50%) translateX(-50%)",
            alignItems: "center",
            color: "grey",
            width: 16,
          }}
        >
          {startAdornment}
        </InputAdornment>
        <StyledTextareaAutosize
          ref={ref}
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          disabled={disabled}
          name={name}
          onFocus={onFocus}
          sx={{
            pl: startAdornment ? 3.5 : 1,
          }}
          minRows={minRows}
          maxRows={maxRows}
          error={error}
        />
      </Box>
    );
  }
);

TextareaAutosize.displayName = "TextareaAutosize";

export default TextareaAutosize;

import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "src/context/UserContext";
import { useAuth0 } from "@auth0/auth0-react";
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
  Box,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

const Profile = () => {
  const { agent } = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { logout } = useAuth0();

  useEffect(() => {
    if (agent === false) {
      setIsModalOpen(true);
    }
  }, [agent]);

  return (
    <Box>
      <Divider />

      {agent && (
        <ListItem sx={{ display: "flex" }}>
          <ListItemAvatar>
            <Avatar src={agent?.avatarLink} />
          </ListItemAvatar>
          <ListItemText
            primary={`${agent?.firstName} ${agent?.lastName}`}
            secondary={agent?.role}
          />
        </ListItem>
      )}

      <Dialog
        open={isModalOpen}
        backdropComponent="div"
        onClose={() => {
          setIsModalOpen(false);
          logout({
            logoutParams: { returnTo: window.location.origin },
          });
        }}
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            boxShadow: "none",
          },
        }}
      >
        <DialogTitle>No Agent Found</DialogTitle>
        <DialogContent>
          <Typography>Please have the admin set up your profile.</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              logout({
                logoutParams: { returnTo: window.location.origin },
              })
            }
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Profile;

import { useState } from "react";
import { omitBy, isEqual } from "lodash";
import { Stack, TextField, Button, Alert } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Loader from "src/components/common/Loader";
import { createCampaignFilter, updateCampaignFilter } from "src/api";
import DefaultClientsFilters from "src/components/common/DefaultClientsFilters";
import { DEFAULT_CLIENTS_FILTERS } from "src/utils/constants";
import { useQueryClient } from "@tanstack/react-query";
import { keys } from "src/utils/react-query/key-factories";

const initialState = {
  id: null,
  name: "",
  filters: { ...DEFAULT_CLIENTS_FILTERS },
};

export const validationSchema = yup.object().shape({
  name: yup.string().required("Filter name is required"),
});

const CampaignFilterForm = ({ initialFormValues, handleClose }) => {
  // Form Submit loading state is handled by Formik's isSubmitting
  const [hasSubmitError, setHasSubmitError] = useState(false);
  const isUpdating = initialFormValues?.id;
  const queryClient = useQueryClient();

  const invalidateCampaignFilters = async () => {
    await queryClient.invalidateQueries({
      queryKey: [keys.campaignFilters],
    });
  };

  const handleFormSubmit = async (values) => {
    // Remove unselected filters from payload
    const filters = omitBy(values.filters, (value, key) => {
      return value === null || value === undefined || isEqual(value, []);
    });

    const payload = {
      ...values,
      filters,
    };

    try {
      if (isUpdating) {
        await updateCampaignFilter(payload);
      } else {
        await createCampaignFilter(payload);
      }
      await invalidateCampaignFilters();
    } catch (error) {
      setHasSubmitError(true);
    } finally {
      handleClose();
    }
  };

  if (!initialFormValues) return null;

  const initialFormState = {
    ...initialState,
    ...initialFormValues,
    filters: {
      ...initialState.filters,
      ...initialFormValues.filters,
    },
  };

  return (
    <Formik
      initialValues={initialFormState}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={handleFormSubmit}
      validationSchema={validationSchema}
    >
      {({
        values,
        handleSubmit,
        isValid,
        isSubmitting,
        setFieldError,
        setFieldValue,
        errors,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Stack spacing={1} useFlexGap>
              <TextField
                fullWidth
                label="Filter Name"
                disabled={isSubmitting}
                name="name"
                value={values.name}
                error={Boolean(errors.name)}
                helperText={errors.name}
                onChange={(e) => {
                  setFieldError("name", undefined);
                  setFieldValue("name", e.target.value);
                }}
              />
              <DefaultClientsFilters
                values={values.filters}
                handleSetFieldValue={(name, value) => {
                  setFieldValue(`filters.${name}`, value);
                }}
              />
              {hasSubmitError && (
                <Alert severity="error" variant="filled">
                  Error {isUpdating ? "updating" : "creating"} Campaign Filter.
                  Please try again.
                </Alert>
              )}
              <Stack direction="row" justifyContent="flex-end" spacing={1}>
                <Button variant="outlined" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  startIcon={isSubmitting && <Loader size="16px" />}
                  variant="contained"
                  type="submit"
                  disabled={!isValid || isSubmitting}
                >
                  Save
                </Button>
              </Stack>
            </Stack>
          </form>
        );
      }}
    </Formik>
  );
};

export default CampaignFilterForm;

import { Container, Grid } from "@mui/material";
import { useSocketRoom } from "src/utils/socket";
import { SOCKET_ROOMS } from "src/utils/constants";
import useDeviceBreakpoint from "src/utils/useDeviceBreakpoint";
import Page from "src/components/common/Page";
import Campaigns from "src/components/Campaigns/Campaigns";
import Composer from "src/components/Campaigns/Composer";
import CampaignFilter from "src/components/Campaigns/CampaignFilter";

const CampaignsPage = () => {
  const { isMobile } = useDeviceBreakpoint();
  useSocketRoom({
    roomName: SOCKET_ROOMS.CAMPAIGNS,
  });

  return (
    <Page title="Campaigns">
      <Container maxWidth="xl" sx={{ p: 3 }}>
        <Grid container spacing={1}>
          <Grid
            item
            container
            sx={{
              alignContent: "start",
            }}
            spacing={1}
            xs={12}
            md={7}
          >
            <Grid item xs={12}>
              <Composer />
            </Grid>
            {isMobile && (
              <Grid item xs={12}>
                <CampaignFilter />
              </Grid>
            )}
            <Grid item xs={12}>
              <Campaigns />
            </Grid>
          </Grid>
          {!isMobile && (
            <Grid item xs={12} md={5}>
              <CampaignFilter />
            </Grid>
          )}
        </Grid>
      </Container>
    </Page>
  );
};

export default CampaignsPage;

import { useState, useContext } from "react";
import { Formik } from "formik";
import {
  Button,
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
  Stack,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import PhoneInput from "src/components/Forms/common/PhoneInput";
import {
  Search as SearchIcon,
  Phone as PhoneIcon,
  User as UserIcon,
  X as CloseIcon,
  Filter as FilterIcon,
} from "react-feather";

import AgentSelect from "src/components/common/AgentSelect";
import { ClientsContext } from "src/context/ClientsContext";
import DefaultClientsFilters from "src/components/common/DefaultClientsFilters";

const Filters = () => {
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const { setFilters, filters, resetFilters } = useContext(ClientsContext);

  const handleSubmitFilters = (filters) => {
    setFilters(filters);
    setIsFilterMenuOpen(false);
  };

  return (
    <Stack direction="row" alignItems="center">
      <Button
        aria-label="Filters"
        id="filter-button"
        color={"primary"}
        variant="contained"
        aria-controls={isFilterMenuOpen ? "filter-menu" : undefined}
        aria-expanded={isFilterMenuOpen ? "true" : undefined}
        aria-haspopup="true"
        onClick={() => setIsFilterMenuOpen(!isFilterMenuOpen)}
        startIcon={<FilterIcon size={16} />}
        size="small"
        sx={{
          height: 30,
        }}
      >
        Filter Clients
      </Button>

      <Dialog
        open={isFilterMenuOpen}
        onClose={() => setIsFilterMenuOpen(false)}
        fullWidth
      >
        <DialogTitle variant="h4">Filters</DialogTitle>

        <IconButton
          sx={{ position: "absolute", top: 8, right: 8 }}
          onClick={() => setIsFilterMenuOpen(false)}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Formik
            enableReinitialize
            initialValues={filters}
            onSubmit={handleSubmitFilters}
          >
            {({ values, handleChange, setFieldValue, handleSubmit }) => {
              return (
                <form
                  onSubmit={(e) => {
                    handleSubmit(e);
                  }}
                >
                  <Stack useFlexGap spacing={2}>
                    {/* Filter Textfields Below */}
                    <Grid container spacing={2}>
                      {/* DISPLAY NAME: */}
                      <Grid item xs={12} lg={6}>
                        <TextField
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SvgIcon fontSize="small">
                                  <SearchIcon />
                                </SvgIcon>
                              </InputAdornment>
                            ),
                          }}
                          name="displayNameKeyword"
                          onChange={handleChange}
                          placeholder="Display Name"
                          value={values.displayNameKeyword}
                          variant="outlined"
                        />
                      </Grid>
                      {/* Client ID */}
                      <Grid item xs={12} lg={6}>
                        <TextField
                          fullWidth
                          type="number"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SvgIcon fontSize="small">
                                  <UserIcon />
                                </SvgIcon>
                              </InputAdornment>
                            ),
                          }}
                          name="clientId"
                          onChange={handleChange}
                          placeholder="Client ID#"
                          value={values.clientId}
                          variant="outlined"
                        />
                      </Grid>
                      {/* PHONE NUMBER */}
                      <Grid item xs={12} lg={6}>
                        <PhoneInput
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SvgIcon fontSize="small">
                                  <PhoneIcon />
                                </SvgIcon>
                              </InputAdornment>
                            ),
                          }}
                          inputComponent="mui-textfield"
                          value={values.phoneNumber}
                          onChange={(value) => {
                            setFieldValue("phoneNumber", value);
                          }}
                          name="phoneNumber"
                          placeholder={`Phone Number`}
                          variant="outlined"
                        />
                      </Grid>
                      {/* Filter Dropdowns Below */}
                      {/* AGENT SELECT */}
                      <Grid item xs={12} lg={6}>
                        <AgentSelect
                          initialAgent={values.agent}
                          handleSetAgent={(agent) => {
                            setFieldValue("agent", agent);
                          }}
                        />
                      </Grid>
                    </Grid>
                    <DefaultClientsFilters
                      values={values}
                      handleSetFieldValue={setFieldValue}
                    />
                    {/* ACTION BUTTONS BELOW: */}
                    <Stack
                      direction="row"
                      useFlexGap
                      spacing={1}
                      justifyContent="space-between"
                    >
                      <Button
                        variant="outlined"
                        onClick={() => setIsFilterMenuOpen(false)}
                      >
                        Close
                      </Button>
                      <Stack direction="row" useFlexGap spacing={1}>
                        <Button
                          fullWidth
                          variant="outlined"
                          onClick={() => resetFilters()}
                        >
                          Reset
                        </Button>
                        <Button
                          sx={{ height: "100%" }}
                          fullWidth
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          Filter
                        </Button>
                      </Stack>
                    </Stack>
                  </Stack>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </Stack>
  );
};

export default Filters;

import axios from "src/utils/axios";
import { toast } from "react-toastify";

export const getThreads = async ({ page, threadFlags }) => {
  try {
    const { data } = await axios.post("/api/clients/threads", {
      page,
      threadFlags,
    });

    return data;
  } catch (error) {
    toast.error("Error fetching messages");
    return null;
  }
};

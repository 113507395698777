import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { createTheme } from "./theme";
import routes, { renderRoutes } from "./routes";
import { BrowserRouter } from "react-router-dom";
import GlobalStyles from "src/components/common/GlobalStyles";
import { SnackbarProvider } from "notistack";
import { ToastContainer } from "react-toastify";
import { UserContextProvider } from "src/context/UserContext";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import TagManager from "react-gtm-module";
import { OptionContextProvider } from "./context/OptionContext";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { enUS } from "@mui/x-date-pickers/locales";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import useHideUnimportantErrors from "src/utils/useHideUnimportantErrors";
import { queryClient } from "src/utils/react-query/queryClient";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
};

TagManager.initialize(tagManagerArgs);

const theme = createTheme({});

function App() {
  useHideUnimportantErrors();
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={enUS}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <QueryClientProvider client={queryClient}>
            <UserContextProvider>
              <OptionContextProvider>
                <CssBaseline />
                <GlobalStyles />
                <BrowserRouter>{renderRoutes(routes)}</BrowserRouter>
              </OptionContextProvider>
            </UserContextProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>

          <ToastContainer />
        </SnackbarProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;

import { useState, useEffect, useContext } from "react";
import axios from "src/utils/axios";
import PropTypes from "prop-types";
import useDeviceBreakpoint from "src/utils/useDeviceBreakpoint";
import {
  Close,
  Flag as FlagIcon,
  AddCircleOutline as AddIcon,
} from "@mui/icons-material";
import {
  ClickAwayListener,
  Fade,
  IconButton,
  MenuItem,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";
import { OptionContext } from "src/context/OptionContext";
import ToolTip from "../ToolTip";
import { useQueryClient } from "@tanstack/react-query";
import { keys } from "src/utils/react-query/key-factories";

const createClientThreadFlag = async (clientId, threadFlagId) => {
  try {
    await axios.post(`/api/clients/${clientId}/thread-flag`, {
      threadFlagId,
    });
  } catch (error) {
    console.error(error.message);
  }
};

const deleteClientThreadFlag = async (clientId, threadFlagId) => {
  try {
    await axios.delete(`/api/clients/${clientId}/thread-flag`, {
      data: { threadFlagId },
    });
  } catch (error) {
    console.error(error.message);
  }
};

const ThreadFlagMultiSelect = ({
  clientId,
  clientThreadFlags: initialClientThreadFlags = [],
  disablePortal = false,
  isWhite = false,
}) => {
  const { threadFlagOptions } = useContext(OptionContext);
  const { isMobile } = useDeviceBreakpoint();
  const [clientThreadFlags, setClientThreadFlags] = useState([]);
  const [isFlagPopperOpen, setIsFlagPopperOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const queryClient = useQueryClient();

  const handleRefetchThreads = () => {
    queryClient.invalidateQueries({
      queryKey: [keys.threads],
      exact: false,
    });
  };

  const handleSetOpen = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setIsFlagPopperOpen((prevState) => !prevState);
  };

  const handleSetClose = (e) => {
    e.stopPropagation();
    setIsFlagPopperOpen(false);
    setAnchorEl(null);
  };

  const handleSetClientThreadFlags = async (option, isThreadFlagSelected) => {
    if (isThreadFlagSelected) {
      await deleteClientThreadFlag(clientId, option.id);
      setClientThreadFlags((prevClientThreadFlags) => {
        const newClientThreadFlags = prevClientThreadFlags.filter(
          (threadFlag) => threadFlag.id !== option.id
        );
        return newClientThreadFlags;
      });
    } else {
      await createClientThreadFlag(clientId, option.id);
      setClientThreadFlags((prevClientThreadFlags) => {
        const newClientThreadFlags = [...prevClientThreadFlags, option];
        return newClientThreadFlags;
      });
      setIsFlagPopperOpen(false);
    }

    handleRefetchThreads();
  };

  useEffect(() => {
    if (initialClientThreadFlags?.length > 0) {
      setClientThreadFlags(initialClientThreadFlags);
    } else {
      setClientThreadFlags([]);
    }
  }, [initialClientThreadFlags]);

  return (
    <ClickAwayListener onClickAway={handleSetClose}>
      <Stack direction="row" alignItems="center" sx={{ overflow: "hidden" }}>
        <ToolTip title="Add Client Thread Flag">
          <IconButton
            ref={anchorEl}
            sx={{
              p: 0,
              color: isWhite ? "white" : "primary",
            }}
            aria-describedby="thread-flag-popper-button"
            aria-controls={isFlagPopperOpen ? "thread-flag-popper" : undefined}
            aria-haspopup="true"
            aria-expanded={isFlagPopperOpen}
            aria-label="Add Client Thread Flag"
            color={isFlagPopperOpen ? "primary" : "inherit"}
            onClick={handleSetOpen}
          >
            <AddIcon fontSize="small" />
          </IconButton>
        </ToolTip>
        <Popper
          id="thread-flag-popper"
          open={isFlagPopperOpen}
          anchorEl={anchorEl}
          transition
          placement="bottom-start"
          disablePortal={disablePortal}
          popperOptions={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, 8],
                },
              },
              {
                name: "flip",
                enabled: false,
              },
            ],
          }}
          sx={{ zIndex: 100 }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper sx={{ position: "relative" }} elevation={10}>
                {isMobile && (
                  <Stack
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: -60,
                      backgroundColor: "background.paper",
                      borderRadius: "50%",
                      boxShadow: (theme) => theme.shadows[10],
                    }}
                    onClick={handleSetClose}
                  >
                    <IconButton size="large">
                      <Close />
                    </IconButton>
                  </Stack>
                )}
                {threadFlagOptions.map((threadFlagOption) => {
                  const isThreadFlagSelected = clientThreadFlags.some(
                    (clientThreadFlag) =>
                      clientThreadFlag.id === threadFlagOption.id
                  );
                  return (
                    <Stack key={threadFlagOption.id} direction="row">
                      <MenuItem
                        sx={{
                          width: "100%",
                          gap: (theme) => theme.spacing(1),
                          py: (theme) => theme.spacing(2),
                          paddingLeft: (theme) => theme.spacing(1),
                          paddingRight: (theme) => theme.spacing(2),
                          borderBottom: (theme) =>
                            `1px solid ${theme.palette.divider}`,
                        }}
                        selected={isThreadFlagSelected}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSetClientThreadFlags(
                            threadFlagOption,
                            isThreadFlagSelected
                          );
                        }}
                      >
                        <FlagIcon sx={{ color: threadFlagOption?.hexCode }} />
                        <Typography variant="body2">
                          {threadFlagOption.label}
                        </Typography>
                      </MenuItem>
                    </Stack>
                  );
                })}
              </Paper>
            </Fade>
          )}
        </Popper>
        <Stack direction="row">
          {clientThreadFlags.length === 0 && (
            <Typography
              variant="body2"
              onClick={handleSetOpen}
              color="text.secondary"
              sx={{
                ml: 0.5,
                cursor: "pointer",
                transition: "color 0.3s ease",
                color: isWhite ? "white" : "text.secondary",
                "&:hover": {
                  color: isWhite ? "white" : "text.secondary",
                  textDecoration: "underline",
                },
              }}
            >
              Add Flag
            </Typography>
          )}
          {clientThreadFlags.map((clientThreadFlag) => {
            const threadFlagOption = threadFlagOptions.find(
              (option) => option.id === clientThreadFlag.id
            );
            return (
              <ToolTip title={clientThreadFlag.label} key={clientThreadFlag.id}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSetClientThreadFlags(clientThreadFlag, true);
                  }}
                  sx={{ color: threadFlagOption?.hexCode, p: 0 }}
                >
                  <FlagIcon
                    fontSize="small"
                    sx={{ color: threadFlagOption?.hexCode }}
                  />
                </IconButton>
              </ToolTip>
            );
          })}
        </Stack>
      </Stack>
    </ClickAwayListener>
  );
};

export default ThreadFlagMultiSelect;

ThreadFlagMultiSelect.propTypes = {
  clientId: PropTypes.string.isRequired,
  clientThreadFlags: PropTypes.array,
  selectedClientId: PropTypes.string,
  sx: PropTypes.object,
};

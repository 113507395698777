import { Card, Grid, CardContent, CardHeader, Divider } from "@mui/material";
import DisplayText from "src/components/common/Crm/DisplayText";
import DisplayChips from "src/components/common/Crm/DisplayChips";
import EditField from "src/components/common/Crm/EditField";

const Stats = ({
  ttcDuration,
  ethnicIdentities,
  genderIdentity,
  age,
  ageRange,
  ivfCycle,
  miscarriageAmount,
  liveBirthAmount,
  pregnancyAmount,
  hasInsurance,
  wellnessStatuses,
  resources,
  currentTreatments,
  priorDiagnosises,
  journeyDifficulties,
  bmi,
  hasInsuranceBenefit,
  relationshipStatus,
  signupAtReStatus,
  signupAtObgynStatus,
  languagePreference,
  handleGetClient,
}) => {
  return (
    <Card elevation={1}>
      <CardHeader title="Client Details" />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          {/* COLUMN 1 */}
          <Grid
            item
            xs={12}
            md={3}
            container
            spacing={0.75}
            alignContent="start"
            alignItems="center"
          >
            <Grid item xs={12}>
              {console.log("relationshipStatus", relationshipStatus)}
              <DisplayText
                primary={"Relationship Status"}
                secondary={relationshipStatus?.shortLabel}
                editor={(params) => (
                  <EditField
                    name="relationshipStatusId"
                    type="single-select"
                    label="Relationship Status"
                    value={relationshipStatus?.shortLabel}
                    optionsKey="relationshipStatusOptions"
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <DisplayText
                primary={"Language Preference"}
                secondary={languagePreference?.label}
                editor={(params) => (
                  <EditField
                    name="languagePreferenceId"
                    type="single-select"
                    label="Language Preference"
                    value={languagePreference?.label}
                    optionsKey="languagePreferenceOptions"
                    handleGetClient={handleGetClient}
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <DisplayText primary={"Age"} secondary={age} />
            </Grid>

            <Grid item xs={12}>
              <DisplayText primary={"Age Range"} secondary={ageRange?.label} />
            </Grid>

            <Grid item xs={12}>
              <DisplayText
                primary="Gender Identity"
                secondary={genderIdentity?.label}
              />
            </Grid>

            <Grid item xs={12}>
              <DisplayChips
                label={"Ethnic Identities"}
                values={ethnicIdentities}
              />
            </Grid>
          </Grid>

          {/* COLUMN 2 */}
          <Grid
            item
            xs={12}
            md={3}
            container
            spacing={0.75}
            alignContent="start"
          >
            <Grid item xs={12}>
              <DisplayText
                primary={"TTC Duration"}
                secondary={ttcDuration?.label}
              />
            </Grid>

            <Grid item xs={12}>
              <DisplayText primary="BMI" secondary={bmi} />
            </Grid>

            <Grid item xs={12}>
              <DisplayText
                primary="# of Pregnancies"
                secondary={pregnancyAmount?.label}
              />
            </Grid>

            <Grid item xs={12}>
              <DisplayText
                primary={"# of Live Births"}
                secondary={liveBirthAmount?.label}
              />
            </Grid>

            <Grid item xs={12}>
              <DisplayText
                primary="# of Miscarriages"
                secondary={miscarriageAmount?.label}
              />
            </Grid>
          </Grid>

          {/* COLUMN 3 */}
          <Grid
            item
            xs={12}
            md={3}
            container
            spacing={0.75}
            alignContent="start"
          >
            <Grid item xs={12}>
              <DisplayText
                primary="Has Insurance"
                secondary={hasInsurance?.label}
                editor={(params) => (
                  <EditField
                    name="hasInsuranceId"
                    type="single-select"
                    label="Has Insurance"
                    value={hasInsurance?.label}
                    optionsKey="hasInsuranceOptions"
                    {...params}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <DisplayText
                primary="Has Benefits"
                secondary={hasInsuranceBenefit?.label}
                editor={(params) => (
                  <EditField
                    name="hasInsuranceBenefitId"
                    type="single-select"
                    label="Has Benefits"
                    value={hasInsuranceBenefit?.label}
                    optionsKey="hasInsuranceBenefitOptions"
                    {...params}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <DisplayText primary={"IVF Cycles"} secondary={ivfCycle?.label} />
            </Grid>

            <Grid item xs={12}>
              <DisplayText
                primary="Had RE At Signup "
                secondary={signupAtReStatus}
              />
            </Grid>

            <Grid item xs={12}>
              <DisplayText
                primary="Had OBGYN At Signup"
                secondary={signupAtObgynStatus}
              />
            </Grid>
          </Grid>

          {/* COLUMN 4 */}
          <Grid
            item
            xs={12}
            md={3}
            container
            spacing={0.75}
            alignContent="start"
          >
            <Grid item xs={12}>
              <DisplayChips
                label={"Current Treatments"}
                values={currentTreatments}
              />
            </Grid>

            <Grid item xs={12}>
              <DisplayChips
                label={"Wellness Status"}
                values={wellnessStatuses}
              />
            </Grid>
            <Grid item xs={12}>
              <DisplayChips
                label={"Journey Difficulties"}
                values={journeyDifficulties}
              />
            </Grid>

            <Grid item xs={12}>
              <DisplayChips
                label={"Fertility Services/Resources"}
                values={resources}
              />
            </Grid>

            <Grid item xs={12}>
              <DisplayChips
                label={"Prior Diagnoses"}
                values={priorDiagnosises}
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Stats;

import { useState, useContext, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import { Link } from "react-router-dom";
import MultiSelect from "src/components/common/MultiSelect";
import { OptionContext } from "src/context/OptionContext";
import { Close as CloseIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import useSearchParam from "src/utils/useSearchParam";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterChip from "src/components/Chat/Navigation/NavigationHeader/FilterChip";

const ThreadsFilter = () => {
  const [filters, setFilters] = useState({});
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const { threadFlagOptions } = useContext(OptionContext);
  const navigate = useNavigate();
  const threadFlagParams = useSearchParam("threadFlags");
  const threadFlags = JSON.parse(threadFlagParams);
  const clientId = useSearchParam("clientId");

  const handleClickSaveFilters = async (newFilters) => {
    const params = new URLSearchParams();

    Object.entries(newFilters).forEach(([key, value]) => {
      if (value !== null) {
        params.append(key, JSON.stringify(value));
      }
    });

    // Add clientId to query params if available
    if (clientId) {
      params.append("clientId", clientId);
    }

    navigate(`/chat${params.toString() ? `?${params}` : ""}`);
    setIsFilterModalOpen(false);
  };

  const handleCancelFilters = () => {
    setIsFilterModalOpen(false);
  };

  const handleClearFilters = () => {
    navigate("/chat");
  };

  // On change of filters on the filter modal:
  const handleSetThreadFilters = (newFilters) => {
    setFilters((prev) => ({
      ...prev,
      ...newFilters,
    }));
  };

  useEffect(() => {
    if (threadFlagParams) {
      setFilters({
        threadFlags: threadFlags,
      });
    } else {
      setFilters({});
    }

    return () => {
      setFilters({});
    };
  }, [threadFlagParams]);

  const hasThreadFilters = filters?.threadFlags?.length > 0;

  return (
    <>
      <Stack direction="column" spacing={1} width="100%">
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Link to="/chat/search" style={{ textDecoration: "none" }}>
            <Button
              onClick={() => navigate("/chat/search")}
              startIcon={<SearchIcon />}
              size="small"
            >
              Search
            </Button>
          </Link>
          <Stack direction="row" spacing={1}>
            {hasThreadFilters && (
              <Button
                size="small"
                variant="outlined"
                onClick={handleClearFilters}
              >
                Clear
              </Button>
            )}
            <Button
              onClick={() => setIsFilterModalOpen(true)}
              startIcon={<FilterAltIcon />}
              variant="contained"
              size="small"
            >
              Filter
            </Button>
          </Stack>
        </Stack>
        {hasThreadFilters && (
          <Stack
            direction="row"
            spacing={0.5}
            sx={{ flexWrap: "wrap" }}
            useFlexGap
          >
            {threadFlags?.map((flag) => (
              <FilterChip
                key={flag.id}
                label={flag.label}
                hexCode={flag.hexCode}
              />
            ))}
          </Stack>
        )}
      </Stack>
      <Dialog
        open={isFilterModalOpen}
        onClose={handleCancelFilters}
        fullWidth
        maxWidth="xs"
        aria-hidden={!isFilterModalOpen}
      >
        <DialogTitle typography="h4">Filter Threads</DialogTitle>
        <IconButton
          sx={{ position: "absolute", top: 8, right: 8 }}
          onClick={handleCancelFilters}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ p: 0 }}>
          <Stack spacing={2} sx={{ p: 2 }} useFlexGap>
            <MultiSelect
              id="threadFlags"
              label="Filter By Thread Flags"
              values={filters?.threadFlags || []}
              options={threadFlagOptions}
              setValues={(values) => {
                handleSetThreadFilters({ threadFlags: values });
              }}
              limitTags={3}
            />
            <Stack direction="row" justifyContent="flex-end" spacing={1}>
              <Button variant="outlined" onClick={handleCancelFilters}>
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => handleClickSaveFilters(filters)}
              >
                Save
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ThreadsFilter;

import axios from "src/utils/axios";
import { toast } from "react-toastify";

export const deleteDraft = async (clientId) => {
  try {
    await axios.delete(`/api/clients/${clientId}/draft`);
  } catch (error) {
    toast.error(`Error deleting draft`);
  }
};

import axios from "src/utils/axios";
import { toast } from "react-toastify";

export const getShortClient = async (clientId) => {
  if (!clientId) {
    return null;
  }
  try {
    const { data } = await axios.get(`/api/clients/${clientId}/short`);
    return data;
  } catch (error) {
    toast.error("Error fetching short client");
  }
};

import { useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Stack,
  OutlinedInput,
  TextField,
} from "@mui/material";
import AutoResizeInput from "src/components/common/AutoResizeInput";
import Test from "src/components/Campaigns/Composer/Test";
import SingleSelect from "src/components/common/SingleSelect";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { keys } from "src/utils/react-query/key-factories";
import { createCampaign } from "src/api";
import { Formik } from "formik";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";

const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  message: yup.string().required("Message is required"),
});

const Composer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();

  const handleInvalidateCampaigns = async () => {
    await queryClient.invalidateQueries({
      queryKey: [keys.campaigns],
    });
  };

  const { data: campaignFiltersData } = useQuery({
    queryKey: [keys.campaignFilters],
    queryFn: () => {},
    enabled: false,
  });
  const campaignFilters = campaignFiltersData?.pages?.flatMap(
    (page) =>
      page?.filters?.map((filter) => ({
        id: filter?.id,
        label: filter?.name,
      })) || []
  );

  const handleCreateCampaign = async (values, { resetForm }) => {
    try {
      setIsLoading(true);
      await createCampaign({
        name: values.name,
        message: values.message,
        batchSize: values.batchSize,
        filterId: values.filterId,
      });

      await handleInvalidateCampaigns();
      resetForm();
      toast.success(`Campaign created successfully`);
    } catch (error) {
      toast.error(`Error creating campaign`);
    } finally {
      setIsLoading(false);
    }
  };

  const initialState = {
    name: "",
    message: "",
    batchSize: 5,
    filterId: 0,
  };

  return (
    <Card>
      <CardHeader title="Campaign Composer" />
      <Divider />
      <CardContent>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleCreateCampaign}
        >
          {({ handleSubmit, values, setFieldValue, errors }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(errors.name)}
                      fullWidth
                      label="Campaign Name"
                      value={values.name}
                      onChange={(e) => setFieldValue("name", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <AutoResizeInput
                      error={Boolean(errors.message)}
                      value={values.message}
                      handleChange={(e) => {
                        e.persist();
                        setFieldValue("message", e.target.value);
                      }}
                      placeholder="Enter SMSText"
                      minRows={4}
                      maxRows={10}
                      sx={{
                        backgroundColor: "background.dark",
                      }}
                    />
                  </Grid>

                  <Grid item container spacing={1} xs={12}>
                    <Grid item xs={12} md={6}>
                      <SingleSelect
                        id="filter"
                        label="Select Filter"
                        placeholder="Select Filter"
                        options={campaignFilters}
                        value={values.filterId || undefined}
                        onChange={(value) => setFieldValue("filterId", value)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <OutlinedInput
                        type="number"
                        placeholder="Batch Size"
                        fullWidth
                        variant="outlined"
                        value={values.batchSize}
                        onChange={(e) =>
                          setFieldValue("batchSize", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item container xs={12}>
                      <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="flex-end"
                        width="100%"
                      >
                        <Button
                          color="error"
                          variant="outlined"
                          size="large"
                          onClick={() => {
                            setFieldValue("message", "");
                            setFieldValue("filterId", null);
                            setFieldValue("batchSize", 5);
                          }}
                        >
                          Clear
                        </Button>
                        <Test message={values.message} />

                        <LoadingButton
                          loading={isLoading}
                          color="primary"
                          variant="contained"
                          size="large"
                          type="submit"
                        >
                          Create
                        </LoadingButton>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default Composer;

import { useState, useEffect, useContext } from "react";
import { UserContext } from "src/context/UserContext";
import {
  Card,
  Typography,
  IconButton,
  SvgIcon,
  Stack,
  Grid,
} from "@mui/material";
import StatusSelect from "./StatusSelect";
import { useNavigate } from "react-router-dom";
import { Trash as DeleteIcon } from "react-feather";
import DeleteConfirmModal from "src/components/common/DeleteConfirmModal";
import Agent from "../Agent";
import ThreadFlagMultiSelect from "src/components/common/ThreadFlagMultiSelect";

import ToolTip from "src/components/common/ToolTip";
import Tags from "./Tags";
import { formatPhoneNumber } from "react-phone-number-input";
import moment from "moment";

const Info = ({
  clientId,
  createdAt,
  displayName,
  firstName,
  isActive,
  inactiveReason,
  phoneNumber,
  zipCode,
  email,
  followUpDate,
  agent,
  threadFlags,
  tags,
}) => {
  const navigate = useNavigate();
  const [clientValues, setClientValues] = useState({});
  const [selectedClientId, setSelectedClientId] = useState(clientId);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(false);
  const { isAgentAdmin } = useContext(UserContext);

  const goToChat = (clientId, displayName) => {
    navigate(`/chat?clientId=${clientId}`, {
      state: {
        displayName,
      },
    });
  };

  useEffect(() => {
    setClientValues({
      clientId,
      createdAt,
      firstName,
      displayName,
      phoneNumber,
      zipCode,
      email,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card elevation={1} sx={{ px: 2, py: 1 }}>
      <Grid container spacing={1} alignItems="center">
        <Grid item container xs={12} md={5} spacing={0.5}>
          <Grid item xs={12}>
            <Stack
              direction="row"
              spacing={1}
              useFlexGap
              alignItems="center"
              sx={{
                justifyContent: { lg: "flex-start" },
              }}
            >
              {/* ROW 1 */}
              <ToolTip title="Go to Chat">
                <Typography
                  sx={{
                    cursor: "pointer",
                    maxWidth: {
                      md: "240px",
                    },
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  onClick={() => goToChat(clientId, displayName)}
                  variant="h4"
                  color="primary"
                >
                  {`${clientValues.displayName}`}
                </Typography>
              </ToolTip>
              {isAgentAdmin && (
                <ToolTip title="Delete Client">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setIsDeleteConfirmModalOpen(true);
                    }}
                  >
                    <SvgIcon fontSize="14px">
                      <DeleteIcon />
                    </SvgIcon>
                  </IconButton>
                </ToolTip>
              )}
            </Stack>
          </Grid>
          {/* ROW 2 */}
          <Grid item xs={12}>
            <Typography
              sx={{
                lineHeight: 1,
                fontSize: 12,
              }}
              variant="subtitle2"
              color="textSecondary"
            >
              {`${moment(createdAt).format("L")} • ${formatPhoneNumber(
                clientValues.phoneNumber
              )} • ${clientValues?.zipCode || "N/A"}`}
            </Typography>
          </Grid>
          {/* ROW 3 */}
          <Grid item xs={12}>
            <ThreadFlagMultiSelect
              clientId={clientId}
              clientThreadFlags={threadFlags}
              selectedClientId={selectedClientId}
              setSelectedClientId={setSelectedClientId}
            />
          </Grid>

          <Grid item xs={12}>
            <Tags tags={tags} />
          </Grid>
        </Grid>
        <Grid
          item
          container
          spacing={1}
          xs={12}
          md={7}
          justifyContent="flex-end"
          alignItems="start"
        >
          <Grid item xs={12} md={8}>
            <Agent
              followUpDate={followUpDate}
              agent={agent}
              clientId={clientId}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <StatusSelect
              clientId={clientId}
              isActive={isActive}
              inactiveReason={inactiveReason}
            />
          </Grid>
        </Grid>
      </Grid>
      {isAgentAdmin && (
        <DeleteConfirmModal
          client={clientValues}
          isOpen={isDeleteConfirmModalOpen}
          setIsOpen={setIsDeleteConfirmModalOpen}
        />
      )}
    </Card>
  );
};

export default Info;

import ScrollBar from "src/components/common/ScrollBar";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import SearchResult from "./SearchResult";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useSearchParam from "src/utils/useSearchParam";
import { getMessages } from "src/api";
import { keys } from "src/utils/react-query/key-factories";
import { useEffect } from "react";

const SearchResults = () => {
  const navigate = useNavigate();
  const selectedMessageId = useSearchParam("messageId");
  const textQuery = useSearchParam("textQuery");
  const userType = useSearchParam("userType");
  const fromDate = useSearchParam("fromDate");
  const toDate = useSearchParam("toDate");
  const queryClient = useQueryClient();

  const {
    isFetching,
    isLoading,
    error,
    data: searchResults = { pages: [{ messages: [] }], pageParams: [0] },
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    retry: 1,
    queryKey: [keys.searchResults],
    queryFn: ({ pageParam = 0 }) =>
      getMessages({ page: pageParam, userType, textQuery, fromDate, toDate }),
    getNextPageParam: (lastPage) => {
      return lastPage?.pagination?.nextPage;
    },
    initialPageParam: 0,
  });

  const resetSearchResults = () => {
    queryClient.resetQueries({
      queryKey: [keys.searchResults],
      type: "inactive",
    });
  };

  const clearSearchResults = () => {
    queryClient.removeQueries({
      queryKey: [keys.searchResults],
      type: "all",
    });
  };

  if (error) {
    toast.error("Error fetching messages");
  }
  const handleSelectSearchItem = (messageId) => {
    let queryParams = `?messageId=${messageId}`;
    if (textQuery) {
      queryParams += `&textQuery=${textQuery}`;
    }
    if (userType) {
      queryParams += `&userType=${userType}`;
    }

    if (fromDate) {
      queryParams += `&fromDate=${fromDate}`;
    }

    if (toDate) {
      queryParams += `&toDate=${toDate}`;
    }

    navigate(`/chat/search${queryParams}`);
  };

  // refetch search results when the textQuery or userType changes
  useEffect(() => {
    resetSearchResults();

    return () => {
      clearSearchResults();
    };
  }, [textQuery, userType, fromDate, toDate]);

  return (
    <ScrollBar
      isLoading={isFetching || isLoading}
      isInitialLoading={isLoading || isFetching}
      fetchNextPage={fetchNextPage}
      hasMore={hasNextPage}
    >
      {searchResults?.pages.flatMap((page) =>
        page.messages.map((message) => (
          <SearchResult
            isSelected={message.id === Number(selectedMessageId)}
            id={message.id}
            createdByAgent={message.createdByAgent}
            createdByClient={message.createdByClient}
            createdAt={message.createdAt}
            body={message.body}
            handleClick={handleSelectSearchItem}
            key={message.id}
            textQuery={textQuery}
          />
        ))
      )}
    </ScrollBar>
  );
};

export default SearchResults;

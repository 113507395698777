import { useState, useEffect } from "react";
import { Formik } from "formik";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  InputAdornment,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Close as CloseIcon } from "@mui/icons-material";
import SingleSelect from "src/components/common/SingleSelect";
import ArticleIcon from "@mui/icons-material/Article";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useNavigate } from "react-router-dom";
import useSearchParam from "src/utils/useSearchParam";
import FilterChip from "src/components/Chat/Navigation/NavigationHeader/FilterChip";
import DateRangePicker from "src/components/common/DateRangePicker";
import moment from "moment";

const SearchFilter = () => {
  const navigate = useNavigate();
  const textQuery = useSearchParam("textQuery");
  const userType = useSearchParam("userType");
  const fromDate = useSearchParam("fromDate");
  const toDate = useSearchParam("toDate");

  const initialSearchFilters = {
    textQuery: "",
    userType: null,
    fromDate: null,
    toDate: null,
  };

  const [searchFilters, setSearchFilters] = useState(initialSearchFilters);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const handleSearchMessages = async (newFilters) => {
    const query = Object.entries(newFilters)
      .filter(([_, value]) => value !== null)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");

    navigate(`/chat/search${query ? `?${query}` : ""}`);

    setIsFilterModalOpen(false);
  };

  const handleCancelFilters = () => {
    setIsFilterModalOpen(false);
  };

  const handleClearFilters = () => {
    navigate("/chat/search");
  };

  const handleDateRangeDisplay = (dateRange) => {
    if (!dateRange.fromDate && !dateRange.toDate) {
      return null;
    }

    const fromDate = dateRange.fromDate
      ? `${moment(dateRange.fromDate).format("L")} - `
      : "Before < ";

    const toDate = dateRange.toDate
      ? moment(dateRange.toDate).format("L")
      : "Present";

    return `${fromDate}${toDate}`;
  };

  const hasMessageFilters = textQuery || userType || fromDate || toDate;

  useEffect(() => {
    setSearchFilters({
      textQuery,
      userType,
      toDate,
      fromDate,
    });
  }, [textQuery, userType, fromDate, toDate]);

  return (
    <>
      <Stack direction="column" spacing={1} width="100%">
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Link to="/chat" style={{ textDecoration: "none" }}>
            <Button startIcon={<ArticleIcon />} size="small">
              Threads
            </Button>
          </Link>
          <Stack direction="row" spacing={1}>
            {hasMessageFilters && (
              <Button
                size="small"
                variant="outlined"
                onClick={handleClearFilters}
              >
                Clear
              </Button>
            )}
            <Button
              onClick={() => setIsFilterModalOpen(true)}
              startIcon={<FilterAltIcon />}
              variant="contained"
              size="small"
            >
              Filter
            </Button>
          </Stack>
        </Stack>
        {hasMessageFilters && (
          <Stack
            direction="row"
            justifyContent="flex-end"
            flexWrap="wrap"
            useFlexGap
            spacing={0.5}
          >
            {[
              textQuery,
              userType,
              handleDateRangeDisplay({ fromDate, toDate }),
            ].map((value) => {
              if (!value) {
                return null;
              }
              return <FilterChip key={value} label={value} />;
            })}
          </Stack>
        )}
      </Stack>
      <Dialog
        open={isFilterModalOpen}
        onClose={handleCancelFilters}
        fullWidth
        maxWidth="xs"
        aria-hidden={!isFilterModalOpen}
      >
        <DialogTitle typography="h4">Filter Messages</DialogTitle>
        <IconButton
          sx={{ position: "absolute", top: 8, right: 8 }}
          onClick={handleCancelFilters}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ p: 0 }}>
          <Formik
            enableReinitialize
            initialValues={searchFilters}
            onSubmit={handleSearchMessages}
          >
            {({ values, setFieldValue, handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Stack spacing={2} sx={{ p: 2 }} useFlexGap>
                    <TextField
                      id="search"
                      placeholder="Search messages"
                      fullWidth
                      value={values.textQuery || ""}
                      onChange={(e) => {
                        setFieldValue("textQuery", e.target.value);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon color="primary" />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <SingleSelect
                      id="userType"
                      label={"Filter by Coach/Client"}
                      value={values?.userType || "-"}
                      options={[
                        {
                          id: "-",
                          label: "-",
                        },
                        {
                          id: "coach",
                          label: "Coach",
                        },
                        {
                          id: "client",
                          label: "Client",
                        },
                      ]}
                      onChange={(value) => {
                        const newValue = value === "-" ? null : value;
                        setFieldValue("userType", newValue);
                      }}
                    />
                    <DateRangePicker
                      label="Date Range"
                      startDate={{
                        label: "From",
                        value: values.fromDate,
                        handleChange: (newValue) => {
                          const newFromDate = newValue
                            ? moment(newValue).format("YYYY-MM-DD")
                            : null;
                          setFieldValue("fromDate", newFromDate);
                        },
                      }}
                      endDate={{
                        label: "To",
                        value: values.toDate,
                        handleChange: (newValue) => {
                          const newToDate = newValue
                            ? moment(newValue).format("YYYY-MM-DD")
                            : null;
                          setFieldValue("toDate", newToDate);
                        },
                      }}
                    />

                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      spacing={1}
                    >
                      <Button variant="outlined" onClick={handleCancelFilters}>
                        Cancel
                      </Button>
                      <Button variant="contained" type="submit">
                        Save
                      </Button>
                    </Stack>
                  </Stack>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SearchFilter;

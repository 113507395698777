import propTypes from "prop-types";
import axios from "src/utils/axios";
import { toast } from "react-toastify";
import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import { Edit } from "react-feather";
import { Close } from "@mui/icons-material";
import { OptionContext } from "src/context/OptionContext";
import SingleSelectAutocomplete from "src/components/common/SingleSelectAutocomplete";
import ToolTip from "src/components/common/ToolTip";
import MultiSelect from "src/components/common/MultiSelect";
import Loader from "src/components/common/Loader";

const handleUpdateCrmOptions = async (clientId, payload) => {
  try {
    await axios.put(`/api/clients/${clientId}/crm-options`, payload);
  } catch (error) {
    throw error;
  }
};

const handleUpdateClient = async (clientId, payload) => {
  try {
    await axios.put(`/api/clients/${clientId}`, payload);
  } catch (error) {
    throw error;
  }
};

const EditField = ({
  name,
  type = "single-select",
  label,
  value,
  values: initialValues,
  optionsKey,
  handleGetClient = null,
  setDisplayTextValue = null,
  setDisplayChipsValues = null,
}) => {
  const { clientId } = useParams();
  const { [optionsKey]: options } = useContext(OptionContext);
  const [valueId, setValueId] = useState(null);
  const [values, setValues] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const formatOptions = (options) => {
    if (options?.some((option) => option?.shortLabel)) {
      return options.map((option) => ({
        id: option.id,
        label: option.shortLabel,
      }));
    }

    return options;
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      if (type === "single-select") {
        const payload = { [name]: valueId };
        await handleUpdateClient(clientId, payload);
        if (setDisplayTextValue) {
          const formattedOptions = formatOptions(options);
          setDisplayTextValue(
            formattedOptions.find((option) => option.id === valueId).label
          );
        }
        setIsOpen(false);
        return;
      }

      if (type === "multi-select") {
        const optionIds = values.map((value) => value.id); // [1,2,3]

        const payload = {
          optionName: name, // "currentTreatment"
          optionIds, // [1,2,3]
        };

        await handleUpdateCrmOptions(clientId, payload);
        if (setDisplayChipsValues) {
          setDisplayChipsValues(
            options.filter((option) => optionIds.includes(option.id))
          );
        }
        setIsOpen(false);
      }
    } catch (error) {
      toast.error("Unable to update client. Please try again.");
      console.error(error);
    } finally {
      setIsLoading(false);
      handleGetClient && handleGetClient();
    }
  };

  useEffect(() => {
    if (type === "single-select") {
      const valueId =
        options?.find((option) =>
          value?.includes(option?.shortLabel || option.label)
        )?.id || null;
      setValueId(valueId);
    }

    if (type === "multi-select") {
      setValues(initialValues);
    }
  }, [type, options, value, initialValues]);

  if (!clientId) return null;

  return (
    <>
      <ToolTip title={`Edit ${label}`} placement="top">
        <IconButton
          size="small"
          onClick={() => setIsOpen(true)}
          color="primary"
        >
          <Edit size="16px" />
        </IconButton>
      </ToolTip>

      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(!isOpen)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle
          sx={{
            position: "relative",
          }}
        >
          Edit {label}
          <IconButton
            sx={{
              position: "absolute",
              top: 4,
              right: 4,
            }}
            aria-label="close"
            onClick={() => setIsOpen(false)}
            size="small"
          >
            <Close size="12px" color="secondary" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2} mt={1}>
            {type === "single-select" && (
              <SingleSelectAutocomplete
                id={name}
                sx={{ mt: 1 }}
                label={label}
                options={formatOptions(options)}
                value={valueId}
                onChange={(valueId) => setValueId(valueId)}
              />
            )}
            {type === "multi-select" && (
              <MultiSelect
                id={name}
                label={label}
                options={options}
                values={values}
                setValues={setValues}
              />
            )}
            <Stack direction="row" justifyContent="flex-end" spacing={1}>
              <Button onClick={() => setIsOpen(false)}>Cancel</Button>
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                disabled={isLoading}
                endIcon={isLoading && <Loader size={16} />}
              >
                Save
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditField;

EditField.propTypes = {
  name: propTypes.string.isRequired,
  type: propTypes.oneOf(["single-select", "multi-select"]),
  label: propTypes.string.isRequired,
  value: propTypes.string,
  values: propTypes.array,
  optionsKey: propTypes.string.isRequired,
};

import PropTypes from "prop-types";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import CampaignFilterForm from "./Form";

const CampaignFilterFormModal = ({
  isOpen,
  handleClose,
  initialFormValues,
}) => {
  return (
    <>
      <Dialog
        aria-hidden="false"
        aria-labelledby="custom-campaign-audience-modal-title"
        open={isOpen}
        onClose={handleClose}
      >
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", top: 5, right: 5 }}
        >
          <Close />
        </IconButton>
        <DialogTitle
          id="custom-campaign-audience-modal-title"
          aria-label="custom-campaign-audience-modal-title"
          typography="h4"
        >
          {initialFormValues?.id ? "Edit" : "Create"} Campaign Filter
        </DialogTitle>
        <DialogContent dividers>
          <CampaignFilterForm
            initialFormValues={initialFormValues}
            handleClose={handleClose}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CampaignFilterFormModal;

CampaignFilterFormModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  initialFormValues: PropTypes.object,
};

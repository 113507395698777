import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const useSearchParam = (param) => {
  const location = useLocation();
  const [value, setValue] = useState(() => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(param);
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setValue(searchParams.get(param));
  }, [location.search, param]);

  return value;
};

export default useSearchParam;

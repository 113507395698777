import React, { useState } from "react";
import axios from "src/utils/axios";
import moment from "moment";
import { useInfiniteQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

import {
  Divider,
  Stack,
  Typography,
  IconButton,
  Card,
  CardContent,
  Grid,
  CardHeader,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DeleteIcon from "@mui/icons-material/Delete";

import { useSocketEvent } from "src/utils/socket";
import { SOCKET_EVENTS } from "src/utils/constants";
import { keys } from "src/utils/react-query/key-factories";
import { getCampaigns } from "src/api";

import ConfirmationModal from "src/components/common/ConfirmationModal";
import ScrollBar from "src/components/common/ScrollBar";
import { DisplayFilterChips } from "src/components/Campaigns/DisplayFilterChips";

const Campaigns = () => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false);
  const [deleteCampaignId, setDeleteCampaignId] = useState(null);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [sendCampaign, setSendCampaign] = useState({});

  const {
    error,
    data: campaignsData,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery({
    retry: 1,
    queryKey: [keys.campaigns],
    queryFn: ({ pageParam }) =>
      getCampaigns({
        page: pageParam,
      }),
    getNextPageParam: (lastPage) => {
      return lastPage?.pagination?.nextPage;
    },
    enabled: true,
    initialPageParam: 0,
  });

  const handleSendCampaign = async () => {
    try {
      await axios.post(
        `/api/campaigns/send/${sendCampaign.campaignId}/batch/${sendCampaign.batchKey}`
      );
      refetch();
      setIsConfirmModalOpen(false);
    } catch (error) {
      toast.error(`Error sending campaign`);
    }
  };

  const handleDeleteCampaign = async () => {
    setIsDeleteLoading(true);
    try {
      await axios.delete(`/api/campaigns/${deleteCampaignId}`);
      refetch();
      setIsConfirmDeleteModalOpen(false);
      setDeleteCampaignId(null);
    } catch (error) {
      toast.error(`Error deleting campaign`);
    } finally {
      setIsDeleteLoading(false);
    }
  };

  useSocketEvent({
    eventName: SOCKET_EVENTS.UPDATE_CAMPAIGNS,
    onEvent: () => {
      console.log("🚨 updating campaigns");
      refetch();
    },
  });

  const isCampaignSending = campaignsData?.pages?.some((campaign) =>
    campaign?.batches?.some((batch) => batch?.isSending)
  );

  return (
    <>
      <Card>
        <CardHeader title="Campaigns" />
        <Divider />
        <CardContent>
          <ScrollBar
            id={keys.campaigns}
            fetchNextPage={fetchNextPage}
            hasMore={hasNextPage}
            isLoading={isFetching || isLoading}
            isInitialLoading={isLoading}
            currentPage={campaignsData?.pageParams || 0}
            hasError={Boolean(error)}
            error={error}
          >
            {campaignsData?.pages?.flatMap((page) =>
              page?.campaigns?.map((campaign, campaignIndex) => {
                const { filters } = campaign;
                const isLastItem = campaignIndex === page.campaigns.length - 1;
                return (
                  <Stack
                    key={campaign.id}
                    spacing={1}
                    useFlexGap
                    sx={{
                      px: 1,
                    }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Stack direction="row" gap={1}>
                        <Typography variant="h5" color="primsecasery">
                          {campaign.name}
                        </Typography>
                        <Typography variant="body2" color="secondary">
                          {`${moment(campaign.createdAt).format("l")}`}
                        </Typography>
                        <Typography variant="body2" color="secondary">
                          {`${campaign.clientsTotal} clients`}
                        </Typography>
                      </Stack>
                      <IconButton
                        onClick={() => {
                          setDeleteCampaignId(campaign.id);
                          setIsConfirmDeleteModalOpen(true);
                        }}
                        aria-label="close"
                        size="small"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Stack>
                    <Typography variant="body2">Filters:</Typography>
                    {filters && Object.keys(filters).length > 0 && (
                      <Stack direction="row" gap={1}>
                        <DisplayFilterChips
                          filters={filters}
                          hasMaxWidth={false}
                        />
                      </Stack>
                    )}
                    <Stack direction="row">
                      <Stack
                        direction="row"
                        px={1}
                        py={1.5}
                        sx={{
                          background: (theme) => theme.palette.grey[100],
                          width: "100%",
                        }}
                      >
                        <Typography>{campaign.message}</Typography>
                      </Stack>
                    </Stack>
                    <Grid container spacing={2}>
                      {Object.keys(campaign.batches).map((key) => {
                        const batch = campaign.batches[key];
                        return (
                          <Grid item container spacing={1} xs={3} key={key}>
                            <Grid item xs={12}>
                              <Typography variant="h5">Batch #{key}</Typography>
                            </Grid>

                            <Grid item xs={12}>
                              <Divider />
                            </Grid>

                            <Grid item xs={12}>
                              <Stack
                                direction="row"
                                gap={2}
                                justifyContent="space-between"
                              >
                                <Typography color="secondary" variant="body2">
                                  # Clients
                                </Typography>
                                <Typography variant="body2">
                                  {batch.clients.length}
                                </Typography>
                              </Stack>
                            </Grid>

                            <Grid item xs={12}>
                              <Stack
                                gap={1}
                                direction="row"
                                justifyContent="space-between"
                                flexWrap="wrap"
                              >
                                <Typography variant="body2" color="secondary">
                                  Completed
                                </Typography>
                                <Typography variant="body2">
                                  {batch.completedDate || "-"}
                                </Typography>
                              </Stack>
                            </Grid>

                            <Grid item xs={12}>
                              <LoadingButton
                                disabled={batch.hasSent || isCampaignSending}
                                loading={batch.isSending}
                                fullWidth
                                onClick={() => {
                                  setIsConfirmModalOpen(true);
                                  setSendCampaign({
                                    campaignId: campaign.id,
                                    batchKey: key,
                                  });
                                }}
                                variant="contained"
                              >
                                Send
                              </LoadingButton>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Grid>
                    {!isLastItem && (
                      <Divider sx={{ my: 1 }} orientation="horizontal" />
                    )}
                  </Stack>
                );
              })
            )}
          </ScrollBar>
        </CardContent>
      </Card>
      {Boolean(campaignsData?.pages.length) && (
        <>
          <ConfirmationModal
            content="Are you sure you want to delete this campaign?"
            ctaText="Delete"
            isModalOpen={isConfirmDeleteModalOpen}
            handleConfirmation={() => handleDeleteCampaign()}
            handleSetConfirmationModal={setIsConfirmDeleteModalOpen}
            isLoading={isDeleteLoading}
          />

          <ConfirmationModal
            content="Are you sure you want to send this batch?"
            ctaText="Send"
            isModalOpen={isConfirmModalOpen}
            handleConfirmation={() => handleSendCampaign()}
            handleSetConfirmationModal={setIsConfirmModalOpen}
          />
        </>
      )}
    </>
  );
};

export default Campaigns;

import React, { forwardRef } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

const Page = forwardRef(({ children, title = "", sx, ...rest }, ref) => {
  return (
    <Box width="100%" height="100%" ref={ref} {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </Box>
  );
});

export default Page;

import { useEffect, useRef } from "react";
import { Stack } from "@mui/material";
import ScrollBar from "src/components/common/ScrollBar";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import Message from "src/components/Chat/Main/Messages/Message";
import { toast } from "react-toastify";
import useSearchParam from "src/utils/useSearchParam";
import { SOCKET_ROOMS, SOCKET_EVENTS } from "src/utils/constants";
import { useSocketEvent, useSocketRoom } from "src/utils/socket/index";
import { getMessages } from "src/api";
import { keyFactories, keys } from "src/utils/react-query/key-factories";

const Messages = () => {
  const selectedClientId = useSearchParam("clientId");
  const selectedMessageId = useSearchParam("messageId");
  const scrollBarRef = useRef(null);
  const queryClient = useQueryClient();

  const resetMessages = () => {
    queryClient.resetQueries({
      queryKey: keyFactories.messages({
        clientId: selectedClientId,
        messageId: selectedMessageId,
      }),
    });
  };

  const removeMessageQueries = ({ isActive }) => {
    queryClient.removeQueries({
      queryKey: [keys.messages],
      type: isActive ? "all" : "inactive",
    });
  };

  const {
    error,
    data: messagesData,
    fetchNextPage,
    hasNextPage,
    refetch: refetchMessages,
    isLoading,
    isFetching,
  } = useInfiniteQuery({
    retry: 1,
    queryKey: keyFactories.messages({
      clientId: selectedClientId,
      messageId: selectedMessageId,
    }),
    queryFn: ({ pageParam }) => {
      return getMessages({
        clientId: selectedClientId,
        messageId: selectedMessageId,
        page: pageParam,
      });
    },
    getNextPageParam: (lastPage) => {
      return lastPage?.pagination?.nextPage;
    },
    enabled: !!selectedClientId || !!selectedMessageId,
    initialPageParam: 0,
  });

  useEffect(() => {
    if (scrollBarRef.current) {
      scrollBarRef.current.scrollTo(0, 0);
    }
  }, [selectedClientId]);

  useSocketRoom({
    roomName: SOCKET_ROOMS.MESSAGES,
    id: selectedClientId,
  });

  useSocketEvent({
    eventName: SOCKET_EVENTS.UPDATE_MESSAGES,
    onEvent: () => {
      // We dont want to refetch if we are on the search page
      if (selectedMessageId) {
        return;
      }

      refetchMessages();
    },
  });

  if (error) {
    toast.error("Error fetching messages");
  }

  // Remove only the inactive message queries when the clientId or messageId changes
  useEffect(() => {
    resetMessages();
  }, [selectedClientId, selectedMessageId]);

  useEffect(() => {
    removeMessageQueries({ isActive: true });
    return () => {
      removeMessageQueries({ isActive: true });
    };
  }, []);

  return (
    <Stack
      direction="column"
      sx={{
        flex: 1,
        minHeight: 0,
        opacity: isLoading ? 0.5 : 1,
      }}
    >
      <ScrollBar
        isLoading={isLoading || isFetching}
        fetchNextPage={({ pageParam }) => {
          fetchNextPage({ pageParam });
        }}
        hasMore={hasNextPage}
        currentPage={messagesData?.pageParams[0]}
        isReverse
        scrollToId={selectedMessageId}
        ref={scrollBarRef}
      >
        {messagesData?.pages[0] &&
          messagesData.pages.flatMap((page) =>
            page?.messages.map((message) => (
              <Message
                key={message.id}
                id={message.id}
                clientId={message.clientId}
                createdByAgent={message.createdByAgent}
                createdByClient={message.createdByClient}
                createdAt={message.createdAt}
                body={message.body}
                isDelivered={message.isDelivered}
                isSelected={message.id === Number(selectedMessageId)}
              />
            ))
          )}
      </ScrollBar>
    </Stack>
  );
};

export default Messages;

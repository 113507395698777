import axios from "src/utils/axios";
import { toast } from "react-toastify";

export const updateClientMessagesToRead = async (clientId) => {
  try {
    await axios.put(`/api/clients/${clientId}/messages/read`);
  } catch (error) {
    toast.error(`Error setting message to read`);
  }
};

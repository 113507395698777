import moment from "moment";

const { Stack, Divider, Alert, Typography } = require("@mui/material");
const { default: DatePicker } = require("../DatePicker");

const DateRangePicker = ({
  label,
  hasDivider = true,
  variant = "outlined",
  hasError = false,
  errorMessage = "",
  startDate: {
    label: startLabel,
    value: startDate,
    handleChange: setStartDate,
  },
  endDate: { label: endLabel, value: endDate, handleChange: setEndDate },
}) => {
  return (
    <Stack spacing={1}>
      <Typography variant="h6" sx={{ mb: 1 }}>
        {label}
      </Typography>
      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={1}
        alignItems="center"
        divider={
          hasDivider && (
            <Divider
              sx={{
                width: (theme) => theme.spacing(1),
                display: { xs: "none", md: "block" },
              }}
            />
          )
        }
      >
        <DatePicker
          fullWidth
          label={startLabel}
          value={startDate}
          maxDate={moment(endDate).add(1, "day").format("YYYY-MM-DD")}
          noPastDateValidation={true}
          handleChange={(value) => {
            setStartDate(value);
          }}
          hasError={hasError}
          variant={variant}
        />
        <DatePicker
          fullWidth
          label={endLabel}
          value={endDate}
          minDate={moment(startDate).add(1, "day").format("YYYY-MM-DD")}
          noPastDateValidation={true}
          hasError={hasError}
          handleChange={(value) => {
            setEndDate(value);
          }}
          variant={variant}
        />
      </Stack>
      {errorMessage && (
        <Alert severity="error" variant="standard">
          {errorMessage}
        </Alert>
      )}
    </Stack>
  );
};

export default DateRangePicker;

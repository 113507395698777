import { useContext, useEffect } from "react";
import { UserContext } from "src/context/UserContext";
import io from "socket.io-client";

export const createSocketInstance = (authToken) => {
  const socket = io(process.env.REACT_APP_API_URL, {
    auth: {
      token: authToken || null,
    },
    transports: ["websocket"],
    reconnection: true,
    reconnectionAttempts: 3,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
  });

  return socket;
};

export const useSocketEvent = ({ eventName, onEvent, deps = [] }) => {
  const { socket } = useContext(UserContext);

  useEffect(() => {
    if (!socket) return;
    socket.on(eventName, onEvent);

    return () => {
      socket.off(eventName, onEvent);
    };
  }, [socket, ...deps]);
};

export const useSocketRoom = ({ roomName, id }) => {
  const { socket } = useContext(UserContext);

  useEffect(() => {
    if (id) {
      socket.emit(`join_${roomName}`, id);
    } else {
      socket.emit(`join_${roomName}`);
    }

    return () => {
      socket.emit(`leave_${roomName}`, id);
    };
  }, [socket, id]);
};

import axiosInstance from "src/utils/axios";

export const getCampaigns = async ({ page }) => {
  try {
    const { data: campaigns } = await axiosInstance.get("/api/campaigns", {
      params: {
        page,
      },
    });

    return campaigns;
  } catch (error) {
    console.error("getCampaigns", error);
    return {
      campaigns: [],
      totalResults: null,
      pageSize: null,
    };
  }
};

import { useEffect, useRef, useCallback, useState, forwardRef } from "react";
import { Alert, Box, CircularProgress, Typography } from "@mui/material";

const ScrollBar = forwardRef(
  (
    {
      id = "",
      fetchNextPage,
      children,
      isLoading,
      hasMore,
      currentPage = 0,
      isReverse,
      scrollToId,
      isInitialLoading,
      hasError = false,
      error = null,
      ...rest
    },
    ref
  ) => {
    const observerRef = useRef(null);

    const [pageNumber, setPageNumber] = useState(currentPage);

    const firstItemRef = useCallback(
      (node) => {
        if (isLoading) {
          return;
        }

        if (observerRef.current) observerRef.current.disconnect();

        observerRef.current = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting && hasMore) {
            console.log("reached the bottom");
            setPageNumber((prevPageNumber) => prevPageNumber - 1);
          }
        });
        if (node) observerRef.current.observe(node);
      },
      [isLoading, hasMore]
    );

    const lastItemRef = useCallback(
      (node) => {
        if (isLoading) {
          return;
        }

        if (observerRef.current) observerRef.current.disconnect();

        observerRef.current = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting && hasMore) {
            console.log("reached the bottom");
            setPageNumber((prevPageNumber) => prevPageNumber + 1);
          }
        });
        if (node) observerRef.current.observe(node);
      },
      [isLoading, hasMore]
    );

    useEffect(() => {
      if (isLoading) {
        return;
      }

      if (pageNumber !== currentPage) {
        fetchNextPage({ pageParam: pageNumber });
      }
    }, [pageNumber]);

    return (
      <Box
        ref={ref}
        height="100%"
        overflow="auto"
        display="flex"
        flexDirection={isReverse ? "column-reverse" : "column"}
        component="ul"
        sx={{
          overflowX: "hidden",
          "&::-webkit-scrollbar:horizontal": {
            display: "none",
          },
          "&::-webkit-scrollbar": {
            width: "8px",
            background: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            background: (theme) => theme.palette.grey[500],
            borderRadius: "10px",
          },
        }}
      >
        {isInitialLoading && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <CircularProgress size={28} />
          </Box>
        )}

        {hasError && (
          <>
            {console.error(`Error => ${id}: ${error}`)}
            <Alert severity="error" sx={{ width: "100%" }}>
              An error occurred while loading data
            </Alert>
          </>
        )}

        {children?.length === 0 && (
          <Box
            display={"flex"}
            height={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography textAlign={"center"}>No data found</Typography>
          </Box>
        )}

        {children?.map((item, index) => {
          let ref = null;
          if (index === 0) {
            ref = firstItemRef;
          } else if (index === children.length - 1) {
            ref = lastItemRef;
          }

          let itemId = "";

          if (scrollToId) {
            if (Number(scrollToId) === item.props.id) {
              itemId = scrollToId;

              setTimeout(() => {
                const element = document.getElementById(itemId);
                if (element) {
                  element.scrollIntoView({ block: "start" });
                }
                // Allow the dom to render the element
              }, 500);
            }
          }

          return (
            <Box
              id={itemId}
              ref={ref}
              key={index}
              component="li"
              sx={{ listStyleType: "none" }}
            >
              {item}
            </Box>
          );
        })}
      </Box>
    );
  }
);

export default ScrollBar;

import {
  colors as muiColors,
  createTheme as createMuiTheme,
  responsiveFontSizes,
} from "@mui/material";
import { softShadows } from "./shadows";
import { typography } from "./typography";
import { formTypography } from "./formTypography";
import {
  CerealMedium,
  CerealBook,
  CerealLight,
  Brandon_med,
  Brandon_reg,
  Brandon_light,
  Brandon_blk,
  Brandon_bld,
} from "./fonts";
import {
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  DESKTOP_BREAKPOINT,
  LARGE_DESKTOP_BREAKPOINT,
} from "src/utils/constants";

const colors = {
  background: {
    dark: "#F4F6F8",
    active: "#F6F7F7",
    chat: "#FBFBFB",
  },
  error: {
    main: "#FA675D",
    light: "#F9D9D6",
    muiError: "#FF0000", // Red Flag
  },
  callToAction: {
    main: "#E3BD6E",
    hover: "#A5884B",
  },
  yellow: {
    golden: "#FFDF00",
    flag: "#FBE941",
    light: "#FFE5B4",
  },
  orange: "#EF8733",
  black: "#000000",
  plum: {
    main: "#731F4A",
    hover: "#9A2963",
    dark: "#641b40",
    alpha: "rgba(115,31,74, 0.25)",
  },
  pink: "#F0627F", // Pink Flag
  purple: "#9747FF", // Purple Flag
  blue: {
    sky: "#89A8D4", // Sky Blue Chip
    baby: "#E9F1F7",
    cyan: "#64b5f6",
    dark: "#0000FF", // Dark Blue Flag
    light: "#77B3DE", // Light Blue Flag
  },
  lavender: "#E6C9E0",
  green: {
    main: "#598972",
    success: "#91D878",
    lime: "#B1FC5C",
    army: "#99992F",
    olive: "#3C4D03", // Olive Green Flag
    approved: "#5CBA4B", // Approved Green Flag
  },
  beige: "#EEE7E5",
  darkBeige: "#DED1CE",
  grey10: "#F8F8F8",
  grey20: "#EBEBEB",
  grey40: "#BEBEBE",
  grey60: "#9D9D9D",
  grey80: "#787878",
  grey100: "#515151",

  brightOrange: "#FF8000",
  brightYellow: "#FFFF00",
  brightRed: "#FF000D",
  brightPurple: "#8A00C4",
  lightBlue: "#ADD8E6",
  lightPink: "#FFB6C1",
  neonPink: "#FF13F0",
  neonBlue: "#2323FF",
  neonGreen: "#39FF14",
};

const themeOverrides = {
  typography: {
    fontFamily: {
      Brandon: "Brandon, sans-serif",
      Cereal: "Cereal, sans-serif",
    },
    ...typography,
    ...formTypography,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Cereal';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('Cereal'), local('Cereal-Medium'), url(${CerealMedium}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'Cereal';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Cereal'), local('Cereal-Book'), url(${CerealBook}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'Cereal';
          font-style: normal;
          font-display: swap;
          font-weight: 300;
          src: local('Cereal'), local('Cereal-Light'), url(${CerealLight}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'Brandon';
          font-style: normal;
          font-display: swap;
          font-weight: 800;
          src: local('Brandon'), local('Brandon-Black'), url(${Brandon_blk}) format("opentype");
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'Brandon';
          font-style: normal;
          font-display: swap;
          font-weight: 600;
          src: local('Brandon'), local('Brandon-Bold'), url(${Brandon_bld}) format("opentype");
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'Brandon';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('Brandon'), local('Brandon-Medium'), url(${Brandon_med}) format("opentype");
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'Brandon';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Brandon'), local('Brandon-Regular'), url(${Brandon_reg}) format("opentype");
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'Brandon';
          font-style: normal;
          font-display: swap;
          font-weight: 300;
          src: local('Brandon'), local('Brandon-Light'), url(${Brandon_light}) format("opentype");
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },

    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Cereal",
          fontWeight: 500,
        },
        contained: {
          boxShadow: "none",
        },
      },
    },

    MuiChip: {
      styleOverrides: {
        root: {
          height: 22,
        },
        label: {
          paddingLeft: 10,
          paddingRight: 10,
        },
        deleteIcon: {
          width: 14,
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: muiColors.common.white,
        },
        tooltip: {
          backgroundColor: muiColors.common.white,
          color: muiColors.grey[600],
          fontSize: "14px",
          boxShadow: `${colors.grey40} 2px 2px 4px 2px`,
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          width: "100%",
          display: "flex",
          flexWrap: "nowrap",
          justifyContent: "center",
          alignItems: "center",
          "@media (max-width: 600px)": {
            flexWrap: "wrap",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          width: "100%",
          height: "100%",

          backgroundColor: "none",
          border: `1px solid ${muiColors.common.black} !important`,
          color: muiColors.common.black,
          "&.Mui-selected": {
            backgroundColor: colors.plum.main,
            color: muiColors.common.white,
            "&:hover": {
              backgroundColor: colors.plum.dark,
            },
          },
          "@media (max-width: 600px)": {
            height: "auto",
          },
        },
      },
    },

    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: "hidden",
        },
      },
    },

    MuiTextField: {
      variants: [
        {
          props: { variant: "form" },
          style: {
            "& .MuiOutlinedInput-root": {
              borderRadius: 0,
              backgroundColor: "white",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            },
          },
        },
      ],
    },

    MuiInputBase: {
      variants: [
        {
          props: { variant: "form" },
          style: {
            backgroundColor: "red",
          },
        },
      ],
      styleOverrides: {
        input: {
          "&::placeholder": {
            opacity: 1,
            color: muiColors.blueGrey[600],
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          backgroundColor: "white",
          "&.Mui-focused": {
            backgroundColor: "white",
          },
          "&:hover": {
            backgroundColor: "white",
          },
        },
        input: {
          padding: "18px 12px 19px",
        },
        underline: {
          "&::before": {
            borderBottom: "0px !important",
          },
          "&::after": {
            borderBottom: 0,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h6: {
          fontWeight: 500,
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: MOBILE_BREAKPOINT, // 600
      md: TABLET_BREAKPOINT, // 960
      lg: DESKTOP_BREAKPOINT, // 1200
      xl: LARGE_DESKTOP_BREAKPOINT, // 1920
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: colors.plum.main,
      light: colors.plum.hover,
      contrastText: muiColors.common.white,
      dark: colors.plum.dark,
      hover: colors.plum.hover,
    },
    secondary: {
      main: colors.grey100,
      light: colors.grey20,
      contrastText: muiColors.common.black,
      dark: colors.grey40,
    },
    action: {
      active: muiColors.blueGrey[600],
    },
    background: {
      default: muiColors.common.white,
      dark: colors.background.dark,
      paper: muiColors.common.white,
      active: colors.background.active,
      chat: colors.background.chat,
      form: colors.beige,
    },
    divider: colors.grey20,

    success: {
      main: colors.green.main,
    },
    error: {
      main: colors.error.main,
      light: colors.error.light,
      muiError: colors.error.muiError,
    },
    callToAction: {
      main: colors.callToAction.main,
      hover: colors.callToAction.hover,
    },
    info: {
      main: colors.blue.sky,
      light: colors.blue.cyan,
    },
    text: {
      primary: colors.black,
      secondary: colors.grey80,
      disabled: colors.grey60,
      white: muiColors.common.white,
    },
    yellow: colors.yellow,
    blue: colors.blue,
    beige: colors.beige,
    darkBeige: colors.darkBeige,
    black: colors.black,
    plum: colors.plum,
    pink: colors.pink,
    skyBlue: colors.blue.sky,
    babyBlue: colors.blue.baby,
    lavender: colors.lavender,
    green: colors.green.main,
    grey10: colors.grey10,
    grey20: colors.grey20,
    grey40: colors.grey40,
    grey60: colors.grey60,
    grey80: colors.grey80,
    grey100: colors.grey100,

    brightOrange: colors.brightOrange,
    brightYellow: colors.brightYellow,
    brightRed: colors.brightRed,
    brightPurple: colors.brightPurple,
    lightBlue: colors.lightBlue,
    lightPink: colors.lightPink,
    neonPink: colors.neonPink,
    neonBlue: colors.neonBlue,
    neonGreen: colors.neonGreen,

    flag: {
      red: colors.error.muiError, // Alert
      yellow: colors.yellow.flag, // Intake Form
      olive: colors.green.olive, // New Sign Up
      purple: colors.purple, // Follow Up Form Received
      blue: colors.blue.dark, // New Question
      lightBlue: colors.blue.light, // Draft Message
      pink: colors.pink, // Virtual Session
      approved: colors.green.approved, // Approved Coach Draft Message to send
    },
  },
  shadows: softShadows,
};

export const createTheme = () => {
  let theme = createMuiTheme(themeOverrides);

  theme = responsiveFontSizes(theme);

  return theme;
};

import { useState, useEffect } from "react";
import {
  Stack,
  Typography,
  Avatar,
  Box,
  Divider,
  Skeleton,
  Button,
  IconButton,
} from "@mui/material";
import { ChevronLeft as ChevronLeftIcon, X as CloseIcon } from "react-feather";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { keyFactories, keys } from "src/utils/react-query/key-factories";
import useSearchParam from "src/utils/useSearchParam";
import { toast } from "react-toastify";
import { formatPhoneNumber } from "react-phone-number-input";
import { format } from "date-fns";
import Tag from "src/components/common/Tag";
import { Link } from "react-router-dom";
import useDeviceBreakpoint from "src/utils/useDeviceBreakpoint";
import { getShortClient } from "src/api";
import { CHAT_DETAILS_WIDTH } from "src/utils/constants";
import ToolTip from "src/components/common/ToolTip";

const Details = () => {
  const { isMobile, isTablet } = useDeviceBreakpoint();
  const [isOpen, setIsOpen] = useState(false);
  const clientId = useSearchParam("clientId");
  const queryClient = useQueryClient();

  const removeClientQuery = ({ isActive }) => {
    queryClient.removeQueries({
      queryKey: [keys.client],
      type: isActive ? "all" : "inactive",
    });
  };

  const {
    data: client,
    isPending,
    error,
  } = useQuery({
    queryKey: keyFactories.client(clientId),
    queryFn: () => getShortClient(clientId),
    enabled: !!clientId,
  });

  if (error) {
    toast.error("Error fetching client");
  }

  useEffect(() => {
    if (isMobile || isTablet) {
      setIsOpen(false);
    }
  }, [isMobile, isTablet]);

  useEffect(() => {
    removeClientQuery({ isActive: false });

    return () => {
      removeClientQuery({ isActive: true });
    };
  }, [clientId]);

  if (!clientId) {
    return null;
  }

  return (
    <Box position="relative" height="100%">
      {!isOpen && (
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => setIsOpen(!isOpen)}
          startIcon={<ChevronLeftIcon size={16} />}
          sx={{
            position: "absolute",
            top: 8,
            right: 16,
            zIndex: 1000,
            background: "white",
            "&:hover": {
              background: "white",
            },
          }}
        >
          Client
        </Button>
      )}
      <Box
        sx={{
          position: {
            xs: "absolute",
            md: "relative",
          },
          right: 0,
          width: isOpen ? CHAT_DETAILS_WIDTH : 0,
          height: "100%",
          transition: "width 0.3s ease-in-out",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            opacity: isOpen ? "block" : "none",
            background: "white",
            borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
            height: "100%",
          }}
        >
          {isPending && <Skeleton variant="rectangular" height="100%" />}
          {client && (
            <Stack p={2} direction="column" spacing={1}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Link
                  to={`/clients/${client?.id}`}
                  color="primary"
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                  }}
                >
                  <Typography variant="h4">{client?.displayName}</Typography>
                </Link>
                <IconButton
                  sx={{
                    color: "primary",
                  }}
                  variant="outlined"
                  onClick={() => setIsOpen(false)}
                  size="small"
                >
                  <ToolTip title="Close">
                    <CloseIcon size={20} />
                  </ToolTip>
                </IconButton>
              </Stack>
              <Divider sx={{ width: "100%" }} />
              <Stack direction="column" spacing={1} sx={{ width: "100%" }}>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                >
                  <Typography variant="subtitle2">Phone</Typography>
                  <Typography variant="body2">
                    {formatPhoneNumber(client?.phoneNumber)}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                >
                  <Typography variant="subtitle2">Status</Typography>
                  <Typography variant="body2">
                    {client?.isActive ? "Active" : "Inactive"}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                >
                  <Typography variant="subtitle2">Sign up date</Typography>
                  <Typography variant="body2">
                    {format(client?.createdAt, "MM/dd/yyyy")}
                  </Typography>
                </Stack>
                <Stack direction="column" spacing={1}>
                  <Typography variant="subtitle2">Tags</Typography>
                  <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
                    {client?.tags?.map((tag) => (
                      <Tag key={tag.id} label={tag.label} />
                    ))}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Details;

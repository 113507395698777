import { Close as CloseIcon } from "@mui/icons-material";
import { useContext, useState } from "react";
import { PhoneInput } from "src/components/Forms/common";
import { UserContext } from "src/context/UserContext";
import axios from "src/utils/axios";
import { LoadingButton } from "@mui/lab";

const {
  Dialog,
  DialogContent,
  Button,
  DialogTitle,
  IconButton,
} = require("@mui/material");
const { toast } = require("react-toastify");

const Test = ({ message, sx }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [testPhoneNumber, setTestPhoneNumber] = useState("");
  const { agent } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleTestMessage = async (message, phoneNumber) => {
    try {
      setIsLoading(true);
      await axios.post("/api/messages/out", {
        body: message,
        phoneNumber,
        auth0Id: agent.auth0Id,
      });
      setTestPhoneNumber("");
      setIsOpen(false);
      toast.success(`Test message sent`);
    } catch (error) {
      toast.error(`Error sending test message`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setTestPhoneNumber("");
  };

  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        color="primary"
        variant="outlined"
        disabled={!message.length}
        sx={sx}
        size="large"
      >
        Test
      </Button>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        maxWidth="xs"
        fullWidth
      >
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", top: 5, right: 5 }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>Send Test Message</DialogTitle>
        <DialogContent dividers sx={{ display: "flex", gap: 1 }}>
          <PhoneInput
            fullWidth
            variant="outlined"
            name="phoneNumber"
            inputComponent={"mui-textfield"}
            placeholder="Phone"
            value={testPhoneNumber}
            onChange={(value) => setTestPhoneNumber(value)}
            sx={{
              "& fieldset": {
                border: (theme) => `1px solid ${theme.palette.divider}`,
                width: "100%",
              },
            }}
          />
          <LoadingButton
            loading={isLoading}
            color="primary"
            variant="contained"
            disabled={!testPhoneNumber || !message.length}
            onClick={() => handleTestMessage(message, testPhoneNumber)}
          >
            Test
          </LoadingButton>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Test;

import moment from "moment";
import ToolTip from "src/components/common/ToolTip";
import { Chip, Stack } from "@mui/material";

const getFilterLabel = ({ key, value }) => {
  if (!value) {
    console.error(`getFilterLabel => missing: ${key}: ${value}`);
    return "NULL";
  }

  switch (key) {
    case "signUpDateFrom": {
      const fromDate = value ? moment(value).format("MM/DD/YYYY") : "Before";
      return `Signup After: ${fromDate}`;
    }
    case "signUpDateTo": {
      const toDate = value ? moment(value).format("MM/DD/YYYY") : "Present";
      return `Signup Until: ${toDate}`;
    }
    case "hasBmiUnder40":
      return "BMI under 40";
    case "hasInsurance":
      return "Has Insurance";
    case "currentAtReStatus":
      return `${value.statusLabel}`;
    case "currentJourneyPathStatus":
      return `${value.statusLabel}`;
    case "currentAtObgynStatus":
      return `${value.statusLabel}`;
    case "currentPregnantStatus":
      return `${value.statusLabel}`;
    case "engagements":
      return `Type: ${value.label}`;
    case "isQualified":
      return "Qualified";
    case "isActive":
      return "Active";
    case "intakeFormCompletion":
      return "Intake Form Completed";
    case "followUpFormCompletion":
      return "Follow Up Form Completed";
    case "hasVirtualSession":
      return "Has Virtual Session";
    case "hasBookedVirtualSession":
      return "Has Booked Virtual Session";
    case "hasCompletedVirtualSession":
      return "Has Completed Virtual Session";
    case "hasInsurance":
      return "Has Insurance";
    case "hasNotReceivedCoachMessage":
      return "Has Not Received Coach Message";

    default:
      return value.label;
  }
};

const formatFilters = (filters) => {
  if (!filters) return {};
  return Object.entries(filters).reduce((acc, [key, value]) => {
    acc[key] = Array.isArray(value) ? value : [value];
    return acc;
  }, {});
};

export const FilterChip = ({ category, item, hasMaxWidth }) => {
  const label = getFilterLabel({ key: category, value: item });
  return (
    <ToolTip title={label} placement="bottom">
      <Chip
        label={label}
        sx={{
          backgroundColor: "babyBlue",
          maxWidth: hasMaxWidth ? "100px" : "500px",
          truncate: hasMaxWidth,
        }}
      />
    </ToolTip>
  );
};

export const DisplayFilterChips = ({
  filters: unformattedFilters,
  hasMaxWidth = true,
}) => {
  const filters = formatFilters(unformattedFilters);
  return (
    <Stack
      direction="row"
      sx={{ flexWrap: "wrap", mb: 2 }}
      spacing={0.5}
      gap={0.5}
      useFlexGap
    >
      {Object.entries(filters).flatMap(([category, filter]) =>
        filter.map((item, index) => {
          return (
            <FilterChip
              key={`${category}-${item.id || index}`}
              category={category}
              item={item}
              hasMaxWidth={hasMaxWidth}
            />
          );
        })
      )}
    </Stack>
  );
};

import { useState, useContext, useRef, useEffect, useCallback } from "react";
import { UserContext } from "src/context/UserContext";
import {
  IconButton,
  SvgIcon,
  Tooltip,
  Stack,
  CircularProgress,
  Typography,
} from "@mui/material";
import { Send as SendIcon, Save as SaveIcon } from "react-feather";
import ConfirmationModal from "src/components/common/ConfirmationModal";
import AutoResizeInput from "src/components/common/AutoResizeInput";
import useSearchParam from "src/utils/useSearchParam";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import { keyFactories } from "src/utils/react-query/key-factories";

import {
  updateClientMessagesToRead,
  sendMessage,
  saveDraft,
  deleteDraft,
  getRecipient,
} from "src/api";
import { toast } from "react-toastify";

const Composer = () => {
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const clientId = useSearchParam("clientId");
  const inputRef = useRef(null);
  const { agent } = useContext(UserContext);

  const queryClient = useQueryClient();

  const { data: recipient, refetch: refetchRecipient } = useQuery({
    queryKey: keyFactories.recipient(clientId),
    enabled: false, // prevent the query from running on initial render
    queryFn: () => getRecipient(clientId),
  });

  // Causes the messages query to refetch when a message is sent:
  const invalidateMessages = (clientId) => {
    queryClient.invalidateQueries({
      queryKey: keyFactories.messages({
        clientId,
      }),
    });
  };

  const handleKeyDown = (event) => {
    if (!!message.trim() && event.keyCode === 13 && !event.shiftKey) {
      setIsModalOpen(true);
      event.preventDefault();
    }
  };

  const handleSend = () => {
    if (!!message.trim()) {
      setIsModalOpen(true);
    }
  };

  const handleSendMessage = async () => {
    try {
      setIsModalOpen(false);
      setIsLoading(true);
      await sendMessage({
        body: message,
        clientId,
        agentId: agent.id,
      });

      setMessage("");

      if (recipient?.draft) {
        await deleteDraft(clientId);
      }

      invalidateMessages(clientId);
      refetchRecipient(clientId);
    } catch (error) {
      console.error(error);
      toast.error("Failed to send message");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveDraft = async (message, clientId) => {
    try {
      await saveDraft({
        body: message,
        clientId,
      });
      refetchRecipient(clientId);

      toast.success("Draft saved");
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteDraft = async (clientId) => {
    try {
      await deleteDraft(clientId);
      refetchRecipient(clientId);
      toast.success("Draft deleted");
    } catch (error) {
      console.error(error);
    }
  };

  // using useCallback to memoize the function to prevent unnecessary re-renders in onChange and onFocus
  const handleClientMessagesToRead = async (clientId) => {
    if (recipient && recipient.hasUnreadClientMessageIds) {
      await updateClientMessagesToRead(clientId);
    }
  };

  const handleChange = (event) => {
    event.persist();
    setMessage(event.target.value);
    handleClientMessagesToRead(clientId);

    if (recipient?.draft && event.target.value === "") {
      handleDeleteDraft(clientId);
    }
  };

  useEffect(() => {
    if (inputRef.current && clientId && !isLoading) {
      inputRef.current.focus();
    }

    return () => {
      setMessage("");
    };
  }, [clientId, isLoading]);

  useEffect(() => {
    if (!recipient) return;

    if (inputRef.current === document.activeElement) {
      handleClientMessagesToRead(clientId);
    }

    if (recipient?.draft) {
      setMessage(recipient.draft);
    }
  }, [recipient]);

  if (!clientId) {
    return null;
  }

  return (
    <>
      <Stack
        direction="row"
        sx={{
          px: 1,
          py: 1,
          background: "white",
        }}
      >
        <Stack
          sx={{
            flexGrow: 1,
            pl: 2,
          }}
        >
          <AutoResizeInput
            ref={inputRef}
            name="Message"
            value={message}
            handleChange={handleChange}
            handleKeyDown={handleKeyDown}
            onFocus={() => handleClientMessagesToRead(clientId)}
            placeholder={
              clientId
                ? `Send a message ${
                    recipient?.displayName ? `to ${recipient?.displayName}` : ""
                  }`
                : `Select a client to type a message`
            }
            autoFocus
            disabled={!clientId || isLoading}
            InputProps={{
              sx: {
                padding: 1.5,
              },
            }}
            startAdornment={
              isLoading ? (
                <CircularProgress color="primary" size={16} />
              ) : (
                <SendIcon />
              )
            }
          />
          <Typography variant="caption" color="text.secondary">
            {new Intl.NumberFormat().format(message.length)} characters
          </Typography>
        </Stack>
        <Stack
          direction="row"
          sx={{
            alignItems: "center",
            mb: 2,
          }}
        >
          <Tooltip title={message ? "Send" : ""}>
            <IconButton
              color="primary"
              disabled={!message}
              onClick={handleSend}
            >
              <SvgIcon fontSize="small">
                <SendIcon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
          <Tooltip title={message ? "Save" : ""}>
            <IconButton
              color="primary"
              disabled={!message || recipient?.draft === message}
              onClick={() => handleSaveDraft(message, clientId)}
            >
              <SvgIcon fontSize="small">
                <SaveIcon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>

      <ConfirmationModal
        content={`Are you sure you want to send this message to ${recipient?.displayName}?`}
        ctaText="Send"
        handleSetConfirmationModal={setIsModalOpen}
        handleConfirmation={handleSendMessage}
        isModalOpen={isModalOpen}
      />
    </>
  );
};

export default Composer;

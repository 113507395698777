import { Stack, Typography, Chip } from "@mui/material";
import WellbeingPoint from "./FivePoints";
import Comment from "./Comment";

const JourneyUpdate = ({ item }) => {
  const {
    comment,
    journeyEvent,
    notAtReReasons,
    // otherNotAtReReason,
    wellbeingPoint,
  } = item;

  return (
    // Comment
    <Stack spacing={1}>
      {Boolean(comment) && <Comment item={item} />}

      {/* Fertility Treatment - Display Chip */}
      {Boolean(journeyEvent) && (
        <Stack useFlexGap>
          <Typography
            sx={{
              fontSize: 12,
            }}
            component="label"
            variant="h6"
          >
            Event
          </Typography>
          <Chip
            color="primary"
            sx={{
              width: "fit-content",
              paddingY: "0.75rem",
            }}
            id={journeyEvent.event.id}
            label={journeyEvent.event.label}
          />
        </Stack>
      )}

      {Boolean(journeyEvent?.otherEvent) && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            sx={{
              fontSize: 12,
            }}
            component="label"
            variant="h6"
          >
            Other Event
          </Typography>
          <Typography variant="body1">{journeyEvent?.otherEvent}</Typography>
        </Stack>
      )}

      {Boolean(notAtReReasons?.length) && (
        <Chip
          label="Not at RE Reasons"
          values={notAtReReasons}
          sx={{
            "& .MuiStack-root": {
              "& .MuiChip-root": {
                maxWidth: "fit-content",
              },
              flexWrap: "wrap",
              gap: 1,
            },
          }}
        />
      )}
      {/* {Boolean(otherNotAtReReason) && (
        <Stack>
          <Typography
            component="label"
            sx={{
              fontSize: 12,
            }}
            variant="h6"
          >
            Other Not At RE Reason
          </Typography>
          <Typography
            variant="body1"
            sx={{
              maxWidth: "100%",
              textDecoration: "underline",
            }}
          >
            {otherNotAtReReason}
          </Typography>
        </Stack>
      )} */}

      {Boolean(wellbeingPoint) && <WellbeingPoint item={wellbeingPoint} />}
    </Stack>
  );
};

export default JourneyUpdate;

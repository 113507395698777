import { TextField, MenuItem } from "@mui/material";
import { useState, useEffect } from "react";
import axios from "src/utils/axios";
import { toast } from "react-toastify";
import InactiveReasonModal from "./InactiveReasonModal";
import Tooltip from "src/components/common/ToolTip";

const options = ["Active", "Inactive"];

const StatusSelect = ({
  isActive,
  clientId,
  inactiveReason: initialInactiveReason,
}) => {
  const [statusLabel, setStatusLabel] = useState(null);
  const [inactiveReason, setInactiveReason] = useState(null);
  const [inactiveReasonModalOpen, setInactiveReasonModalOpen] = useState(false);

  const handleStatusUpdate = async (status, inactiveReason) => {
    try {
      await axios.put(`/api/clients/${clientId}`, {
        isActive: status === "Active" ? true : false,
        inactiveDate: status === "Active" ? null : new Date(),
        inactiveReasonId: status === "Active" ? null : inactiveReason.id,
      });
      setStatusLabel(status);
      setInactiveReason(status === "Active" ? null : inactiveReason.label);
    } catch (error) {
      toast.error(`Error updating status`);
    }
  };

  const handleOnChange = ({ target }) => {
    const { value } = target;
    if (value === "Inactive") {
      setInactiveReasonModalOpen(true);
    } else {
      handleStatusUpdate(value);
    }
  };

  useEffect(() => {
    if (isActive) {
      setStatusLabel("Active");
      setInactiveReason(null);
    } else {
      setStatusLabel("Inactive");
      setInactiveReason(initialInactiveReason);
    }
  }, [isActive, initialInactiveReason]);

  return (
    <>
      <Tooltip title={inactiveReason?.label || ""} placement="top">
        <TextField
          variant="outlined"
          value={statusLabel || ""}
          select
          onChange={handleOnChange}
          sx={{
            width: "100%",
            textAlign: "center",
            "& svg": {
              color: "white",
            },
            "& .MuiOutlinedInput-root": {
              color: "white",

              backgroundColor: (theme) =>
                statusLabel === "Active"
                  ? theme.palette.green
                  : theme.palette.error.main,
            },
            "& fieldset": {
              border: "none",
            },
          }}
        >
          {options.map((option) => {
            return (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            );
          })}
        </TextField>
      </Tooltip>
      <InactiveReasonModal
        inactiveReasonModalOpen={inactiveReasonModalOpen}
        setInactiveReasonModalOpen={setInactiveReasonModalOpen}
        handleStatusUpdate={handleStatusUpdate}
      />
    </>
  );
};

export default StatusSelect;

import { Stack, Divider, Box } from "@mui/material";
import SearchFilter from "./SearchFilter";
import FilterThreads from "./ThreadsFilter";

const NavigationHeader = ({ isSearchRoute }) => {
  return (
    <Box>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          p: 1,
        }}
      >
        {isSearchRoute ? <SearchFilter /> : <FilterThreads />}
      </Stack>
      <Divider />
    </Box>
  );
};

export default NavigationHeader;

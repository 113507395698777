import { useNavigate } from "react-router-dom";
import { IconButton, SvgIcon } from "@mui/material";
import { Person as PersonIcon } from "@mui/icons-material";

function Profile({ id, isWhite }) {
  const navigate = useNavigate();

  if (!id) return null;

  const handleProfileClick = (e, id) => {
    e.stopPropagation();
    navigate(`/clients/${id}`);
  };

  return (
    <IconButton
      sx={{
        p: 0,
        cursor: "pointer",
      }}
      size="small"
      onClick={(e) => handleProfileClick(e, id)}
    >
      <SvgIcon
        sx={{
          color: (theme) => (isWhite ? "white" : theme.palette.grey[400]),
          "&:hover": {
            color: (theme) =>
              isWhite ? theme.palette.grey[300] : theme.palette.primary.main,
          },
        }}
      >
        <PersonIcon />
      </SvgIcon>
    </IconButton>
  );
}

export default Profile;

import axios from "src/utils/axios";
import { toast } from "react-toastify";

export const getMessages = async ({
  clientId = null,
  messageId = null,
  page = 0,
  userType = null,
  textQuery = null,
  toDate = null,
  fromDate = null,
}) => {
  try {
    const dateRange = Boolean(toDate || fromDate)
      ? { from: fromDate, to: toDate }
      : null;

    const { data } = await axios.post("/api/messages", {
      clientId,
      page,
      messageId,
      userType,
      textQuery,
      dateRange,
    });

    return data;
  } catch (error) {
    toast.error("Error fetching messages");
    return null;
  }
};

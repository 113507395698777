import axios from "src/utils/axios";
import { toast } from "react-toastify";

export const createCampaignFilter = async (payload) => {
  try {
    const { data } = await axios.post("/api/campaigns/filters", payload);
    return data;
  } catch (error) {
    toast.error("Error creating campaign filter");
    return null;
  }
};

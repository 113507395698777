import { useState, useEffect } from "react";
import { Button, Stack, FormLabel } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "src/utils/axios";
import { toast } from "react-toastify";
import AutoResizeInput from "src/components/common/AutoResizeInput";
import { useSocketEvent } from "src/utils/socket";
import { SOCKET_EVENTS } from "src/utils/constants";

const handleSaveMessage = async (body, id) => {
  try {
    await axios.put(`/api/settings/automated-messages/${id}`, {
      body,
    });
    toast.success("Updated");
  } catch (error) {
    toast.error("Error to update automated messages");
  }
};

const MessagesForm = () => {
  const [messages, setMessages] = useState([]);

  const socketHandleUpdateAutomatedMessage = (message) => {
    setMessages((prevMessages) => {
      return prevMessages.map((prevMessage) => {
        if (prevMessage.id === message.id) {
          return message;
        }
        return prevMessage;
      });
    });
  };

  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get(`/api/settings/automated-messages/`);
        setMessages(data);
      } catch (error) {
        toast.error("Error to get automated messages");
      }
    })();
  }, []);

  useSocketEvent({
    eventName: SOCKET_EVENTS.UPDATE_AUTOMATED_MESSAGE,
    onEvent: socketHandleUpdateAutomatedMessage,
  });

  return (
    <Stack useFlexGap spacing={2} my={4}>
      {messages?.map(({ id, body, label, stringId }) => (
        <Formik
          key={id}
          initialValues={{ [`${stringId}`]: body }}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize
          onSubmit={(value) => handleSaveMessage(value[stringId], id)}
          validationSchema={Yup.object().shape({
            [`${stringId}`]: Yup.string().required("Required"),
          })}
        >
          {({ values, errors, handleChange, handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Stack useFlexGap spacing={1}>
                  <FormLabel>{label}</FormLabel>
                  <AutoResizeInput
                    key={id}
                    id={stringId}
                    name={stringId}
                    label={label}
                    value={values[stringId]}
                    handleChange={handleChange}
                    errorMessage={errors?.body}
                    minRows={5}
                    maxRows={50}
                  />
                  <Button
                    sx={{ alignSelf: "flex-end" }}
                    variant="contained"
                    color="primary"
                    disabled={!values[stringId]}
                    type="submit"
                  >
                    SAVE
                  </Button>
                </Stack>
              </form>
            );
          }}
        </Formik>
      ))}
    </Stack>
  );
};

export default MessagesForm;

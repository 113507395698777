import { useState, useEffect } from "react";
import { Stack } from "@mui/material";
import axios from "src/utils/axios";
import { toast } from "react-toastify";
import AgentSelect from "src/components/common/AgentSelect";
import DatePicker from "src/components/common/DatePicker";

const Agent = ({
  agent: initialAgent,
  followUpDate: initialFollowUpDate,
  clientId,
}) => {
  const [followUpDate, setFollowUpDate] = useState(null);
  const handleSubmitFollowUpDate = async (value) => {
    try {
      const newDate = value ? new Date(value) : null;
      if (newDate) {
        newDate.setUTCHours(0, 0, 0, 0);
      }

      await axios.put(`/api/clients/${clientId}`, {
        followUpDate: newDate,
      });

      setFollowUpDate(value);
    } catch (error) {
      toast.error(`Error updating follow up date`);
      throw error;
    }
  };

  useEffect(() => {
    if (initialFollowUpDate) {
      setFollowUpDate(initialFollowUpDate);
    }
  }, [initialFollowUpDate]);

  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      sx={{
        width: { xs: "100%", md: "auto" },
      }}
      spacing={{ xs: 1 }}
    >
      <AgentSelect initialAgent={initialAgent} clientId={clientId} />

      <DatePicker
        value={followUpDate}
        minDate={new Date()}
        label="Follow Up"
        handleChange={(date) => handleSubmitFollowUpDate(date)}
        variant="outlined"
      />
    </Stack>
  );
};

export default Agent;

import axios from "src/utils/axios";
import { toast } from "react-toastify";

export const deleteCampaignFilter = async (filterId) => {
  try {
    const { data } = await axios.delete(`/api/campaigns/filters/${filterId}`);
    return data;
  } catch (error) {
    toast.error("Error deleting campaign filter");
    return null;
  }
};

import React from "react";
import PropTypes from "prop-types";
import { Autocomplete, TextField, FormControl } from "@mui/material";

/**
 * A dropdown component that allows single selections.
 * @param {string} id - The id of the dropdown.
 * @param {string} label - The label and placeholder of the dropdown.
 * @param {number} value - The selected value. (id)
 * @param {object} options - The options to display in the dropdown.
 * @param {Function} onChange - The function to set the selected value. (id)
 * @param {string} variant - The variant of the dropdown. (outlined or standard)
 * @param {object} rest - The rest of the props.
 *
 */
const SingleSelectAutocomplete = ({
  id,
  label,
  value,
  options,
  onChange,
  variant = "outlined",
  hasError,
  hasWhiteBackground,
  autoComplete,
  ...rest
}) => {
  return (
    <FormControl fullWidth sx={{ height: "100%" }}>
      <Autocomplete
        id={id}
        options={options}
        getOptionLabel={(option) => option.label || ""}
        value={options.find((option) => option.id === value) || null}
        onChange={(event, newValue) => {
          const value = newValue ? newValue.id : null;
          onChange(value, newValue);
        }}
        sx={{
          "& .MuiAutocomplete-inputRoot": {
            backgroundColor: hasWhiteBackground ? "white" : "transparent",
            border: (theme) =>
              hasError ? `1px solid ${theme.palette.error.main}` : "inherit",
          },
          ...rest.sx,
        }}
        autoComplete={autoComplete}
        slotProps={{
          popper: {
            sx: {
              "& .MuiAutocomplete-listbox": {
                maxHeight: 250,
              },
            },
            modifiers: [
              {
                name: "flip",
                enabled: false,
              },
            ],
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant={variant}
            error={hasError}
          />
        )}
        {...rest}
      />
    </FormControl>
  );
};

export default SingleSelectAutocomplete;

SingleSelectAutocomplete.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      shortLabel: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(["outlined", "standard", "filled"]),
  hasError: PropTypes.bool,
  rest: PropTypes.any,
};

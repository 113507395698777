import { useRef } from "react";
import Page from "src/components/common/Page";
import {
  Divider,
  Card,
  CardHeader,
  Container,
  CardContent,
} from "@mui/material";
import AwayToggle from "src/components/Settings/AwayToggle";
import MessagesForm from "src/components/Settings/MessagesForm";
import { useSocketRoom } from "src/utils/socket";
import { SOCKET_ROOMS } from "src/utils/constants";

const Settings = () => {
  const pageRef = useRef(null);
  useSocketRoom({
    roomName: SOCKET_ROOMS.SETTINGS,
  });
  return (
    <Page title="Settings" ref={pageRef}>
      <Container
        maxWidth="sm"
        sx={{
          p: {
            xs: 0,
            sm: 3,
          },
        }}
      >
        <Card>
          <CardHeader
            title="Settings"
            titleTypographyProps={{ variant: "h4" }}
          />
          <Divider />

          <CardContent>
            <AwayToggle />
            <Divider />
            <MessagesForm />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default Settings;

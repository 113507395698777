import Stack from "@mui/material/Stack";
import Tag from "src/components/common/Tag";

const Tags = ({ tags }) => {
  if (!tags) {
    return null;
  }

  return (
    <Stack direction="row" spacing={0.5}>
      {tags.map((tag) => (
        <Tag key={tag.id} label={tag.label} />
      ))}
    </Stack>
  );
};

export default Tags;

import { Stack } from "@mui/material";
import { useLocation } from "react-router-dom";
import Threads from "./Threads";
import NavigationHeader from "./NavigationHeader";
import SearchResults from "./SearchResults";
import { CHAT_NAVIGATION_WIDTH } from "src/utils/constants";

const Navigation = () => {
  const location = useLocation();

  const isSearchRoute = location.pathname.includes("search");

  return (
    <Stack
      sx={{
        height: "100%",
        width: CHAT_NAVIGATION_WIDTH,
        borderRight: (theme) => `1px solid ${theme.palette.divider}`,
        display: "flex",
        backgroundColor: "white",
      }}
      direction="column"
    >
      <NavigationHeader isSearchRoute={isSearchRoute} />
      {isSearchRoute ? <SearchResults /> : <Threads />}
    </Stack>
  );
};

export default Navigation;

import { Stack } from "@mui/material";
import Navigation from "src/components/Chat/Navigation";
import Main from "src/components/Chat/Main";
import Page from "src/components/common/Page";
const Chat = () => {
  return (
    <Page title="Chat">
      <Stack
        direction="row"
        sx={{
          height: "100%",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Navigation />
        <Main />
      </Stack>
    </Page>
  );
};

export default Chat;

import axios from "src/utils/axios";
import { toast } from "react-toastify";

export const updateCampaignFilter = async (payload) => {
  try {
    const { data } = await axios.put(
      `/api/campaigns/filters/${payload.id}`,
      payload
    );
    return data;
  } catch (error) {
    toast.error("Error updating campaign filter");
  }
};

import axios from "src/utils/axios";
import { toast } from "react-toastify";

export const getRecipient = async (clientId) => {
  try {
    if (!clientId) {
      return null;
    }

    const { data: thread } = await axios.get(
      `/api/clients/threads/${clientId}`
    );
    return thread;
  } catch (error) {
    toast.error("Error fetching recipient");
  }
};

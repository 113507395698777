import { Tooltip, Stack, Box, Typography } from "@mui/material";
import { Flag as FlagIcon } from "@mui/icons-material";

const FilterChip = ({ label, hexCode }) => {
  return (
    <Stack direction="row" key={label}>
      <Tooltip title={label}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            border: (theme) => `1px solid ${theme.palette.divider}`,
            borderRadius: 50,
            px: 1,
            maxWidth: 200,
            ellipsis: true,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            height: 22,
          }}
        >
          {hexCode && <FlagIcon fontSize="small" sx={{ color: hexCode }} />}
          <Typography variant="body2">{label}</Typography>
        </Box>
      </Tooltip>
    </Stack>
  );
};

export default FilterChip;

import { useState, useMemo } from "react";
import {
  Button,
  Dialog,
  Paper,
  CardHeader,
  IconButton,
  SvgIcon,
} from "@mui/material";
import { Plus, X as XIcon } from "react-feather";

import ClientJourneyForm from "src/components/Client/ClientJourney/Update/Form";

const ClientJourneyFormModal = ({ onSubmit, clientId }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOnSubmit = (newValues, resetForm) => {
    onSubmit(newValues, resetForm);
    setIsOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        startIcon={<Plus size={16} />}
        color="primary"
        size="small"
        onClick={() => setIsOpen(true)}
        sx={{
          py: 0.4,
        }}
      >
        Add Update
      </Button>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        fullWidth
        maxWidth="xs"
        aria-modal="true"
      >
        <Paper sx={{ p: 2 }}>
          <CardHeader
            title="Add Client Journey Update"
            sx={{
              pt: 0,
              px: 0,
            }}
            action={
              <IconButton onClick={() => setIsOpen(false)}>
                <SvgIcon fontSize="small">
                  <XIcon />
                </SvgIcon>
              </IconButton>
            }
          />
          {useMemo(() => {
            return (
              <ClientJourneyForm
                onSubmit={(values, resetForm) =>
                  handleOnSubmit(values, resetForm)
                }
                onCancel={() => setIsOpen(false)}
                buttonText="Save"
                clientId={clientId}
              />
            );
          }, [])}
        </Paper>
      </Dialog>
    </>
  );
};

export default ClientJourneyFormModal;

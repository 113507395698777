import axios from "src/utils/axios";
import { toast } from "react-toastify";

export const saveDraft = async ({ body, clientId }) => {
  try {
    await axios.post(`/api/clients/${clientId}/draft`, {
      message: body,
    });
  } catch (error) {
    toast.error(`Error saving draft`);
  }
};

import React from "react";
import { Stack, IconButton, Typography } from "@mui/material";
import { Trash, Edit } from "react-feather";
import { DisplayFilterChips } from "src/components/Campaigns/DisplayFilterChips";

const CampaignFilter = ({ filter, handleEdit, handleDelete }) => {
  const { name, filters } = filter;

  return (
    <Stack spacing={0.5} useFlexGap>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: "100%" }}
      >
        <Typography variant="h6">{name}</Typography>
        <Stack direction="row">
          <IconButton size="small" color="primary" onClick={handleEdit}>
            <Edit size={16} />
          </IconButton>
          <IconButton size="small" color="primary" onClick={handleDelete}>
            <Trash size={16} />
          </IconButton>
        </Stack>
      </Stack>
      {filters && Object.keys(filters).length > 0 && (
        <DisplayFilterChips filters={filters} />
      )}
    </Stack>
  );
};

export default CampaignFilter;

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Skeleton } from "@mui/material";
import ThreadItem from "./ThreadItem";
import ScrollBar from "src/components/common/ScrollBar";
import { toast } from "react-toastify";
import { useSocketEvent, useSocketRoom } from "src/utils/socket";
import useSearchParam from "src/utils/useSearchParam";
import { SOCKET_ROOMS, SOCKET_EVENTS } from "src/utils/constants";
import {
  useQuery,
  useInfiniteQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { keyFactories, keys } from "src/utils/react-query/key-factories";
import { getRecipient, getThreads, updateClientMessagesToRead } from "src/api";

const Threads = () => {
  const navigate = useNavigate();
  const selectedClientId = useSearchParam("clientId");
  const threadFlagParams = useSearchParam("threadFlags");
  const threadFlags = JSON.parse(threadFlagParams);
  const queryClient = useQueryClient();

  const {
    isLoading,
    isFetching,
    error,
    data: threadsData,
    fetchNextPage,
    hasNextPage,
    refetch: refetchThreads,
  } = useInfiniteQuery({
    retry: 1,
    queryKey: [keys.threads],
    queryFn: ({ pageParam }) =>
      getThreads({
        page: pageParam,
        threadFlags: threadFlags,
      }),
    getNextPageParam: (lastPage) => {
      return lastPage.pagination.nextPage;
    },
    refetchOnMount: false, // refetch happens in resetThreads
    initialPageParam: 0,
  });

  const resetThreads = () => {
    // Each time threadFlags change,
    // or when the component unmounts,
    // reset the threads query is called instead of resetThreads
    // to reset pagination and prevent multiple API calls for each page
    queryClient.resetQueries({
      queryKey: [keys.threads],
    });
  };

  const {
    data: recipient = null,
    isFetching: isRecipientFetching,
    refetch: refetchRecipient,
  } = useQuery({
    queryKey: keyFactories.recipient(selectedClientId),
    queryFn: () => getRecipient(selectedClientId),
    enabled: !!selectedClientId,
    retry: 1,
  });

  const removeInactiveRecipientQuery = () => {
    queryClient.removeQueries({
      queryKey: [keys.recipient],
      type: "inactive",
    });
  };

  const handleSelectThread = async ({
    clientId,
    hasUnreadClientMessageIds,
  }) => {
    if (!clientId) {
      return;
    }

    if (hasUnreadClientMessageIds) {
      await updateClientMessagesToRead(clientId);
    }

    // If the client is the same as the selected client, do not navigate
    if (clientId === Number(selectedClientId)) {
      return;
    }

    const params = new URLSearchParams();

    params.append("clientId", clientId);

    if (threadFlags) {
      params.append("threadFlags", JSON.stringify(threadFlags));
    }

    navigate(`/chat?${params}`);
  };

  useSocketRoom({
    roomName: SOCKET_ROOMS.THREADS,
  });

  useSocketEvent({
    eventName: SOCKET_EVENTS.UPDATE_THREADS,
    onEvent: (clientId) => {
      // Only refetch the recipient if the selected client
      // is the same as the updated client
      if (recipient?.id === Number(clientId)) {
        refetchRecipient();
      }

      refetchThreads();
    },
    // NEED to add recipient as a dependency in order to
    // have the conditional statement above to work
    deps: [recipient],
  });

  useEffect(() => {
    // Refetch threads when the threadFlags change
    resetThreads();
    // Removal of threads cache completely happens Navigation component
    // instead of Threads component to allow for a more seamless transition
    // between threads and search results
  }, [threadFlagParams]);

  if (error) {
    toast.error("Error fetching threads");
  }

  useEffect(() => {
    removeInactiveRecipientQuery();

    return () => {
      removeInactiveRecipientQuery();
    };
  }, [selectedClientId]);

  return (
    <Box
      display="flex"
      height="100%"
      flexDirection="column"
      sx={{
        height: "100%",
        flex: 1,
        overflow: "auto",
      }}
    >
      {!recipient && isRecipientFetching && (
        <Skeleton variant="rectangular" height={80} />
      )}
      {recipient && (
        <ThreadItem
          handleClick={handleSelectThread}
          key={recipient.id}
          isSelected={recipient.id === Number(selectedClientId)}
          id={recipient.id}
          hasUnreadClientMessageIds={recipient.hasUnreadClientMessageIds}
          displayName={recipient.displayName}
          lastMessage={recipient.lastMessage}
          agent={recipient.agent}
          isActive={recipient.isActive}
          threadFlags={recipient.threadFlags}
        />
      )}

      <ScrollBar
        isLoading={isFetching}
        fetchNextPage={fetchNextPage}
        hasMore={hasNextPage}
        currentPage={threadsData?.pageParams[0]}
        isInitialLoading={isLoading}
      >
        {threadsData?.pages.flatMap((page) =>
          page.threads?.map((thread) => {
            if (thread.id === Number(selectedClientId)) {
              return null;
            }

            return (
              <ThreadItem
                key={thread.id}
                handleClick={handleSelectThread}
                id={thread.id}
                hasUnreadClientMessageIds={thread.hasUnreadClientMessageIds}
                displayName={thread.displayName}
                lastMessage={thread.lastMessage}
                agent={thread.agent}
                threadFlags={thread.threadFlags}
              />
            );
          })
        )}
      </ScrollBar>
    </Box>
  );
};

export default Threads;

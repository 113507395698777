import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Avatar, Box, Stack, Typography, Button } from "@mui/material";
import {
  Face as FaceIcon,
  ErrorOutline as ErrorOutlineIcon,
} from "@mui/icons-material";
import Linkifyjs from "linkifyjs/react";
import { Link } from "react-router-dom";
const Message = ({
  id,
  createdByAgent = true,
  createdByClient = false,
  createdAt = new Date(),
  body = "",
  isDelivered = true,
  isSelected = false,
  clientId = null,
}) => {
  const isAgent = !!createdByAgent;

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        px: 2,
        alignItems: "center",
        "& a": {
          color: "white",
        },
        justifyContent: isAgent ? "flex-end" : "flex-start",
        backgroundColor: (theme) =>
          isSelected ? theme.palette.grey20 : "transparent",
      }}
    >
      <Stack
        id={id}
        direction={isAgent ? "row" : "row-reverse"}
        spacing={1}
        sx={{
          maxWidth: "500px",
          mx: 2,
          pb: 1,
        }}
      >
        <Box pt={4}>
          <Avatar
            size="small"
            sx={{
              backgroundColor: "white",
              color: "primary.main",
              border: (theme) => `1px solid ${theme.palette.secondary.main}`,
              height: 36,
              width: 36,
            }}
            src={createdByAgent?.avatarLink}
          >
            {createdByAgent && <FaceIcon color="primary" />}
            {createdByClient && createdByClient.displayName[0]}
          </Avatar>
        </Box>
        <Stack spacing={1}>
          <Stack>
            <Typography noWrap color="textSecondary" variant="caption">
              {moment(createdAt).format("l, h:mm:ss a")}
            </Typography>
          </Stack>
          <Stack
            sx={{
              wordBreak: "break-word",
              backgroundColor: isAgent ? "primary.main" : "white",
              color: isAgent ? "white" : "text.primary",
              py: 1,
              px: 2,
              borderRadius: 1,
              boxShadow: 1,
            }}
            spacing={1}
          >
            <Typography variant="h6">
              {createdByClient?.displayName ||
                createdByAgent?.firstName ||
                "N/A"}
            </Typography>
            <Typography color="inherit" variant="body1">
              <Linkifyjs>{body}</Linkifyjs>
            </Typography>
          </Stack>
          {isDelivered === false && (
            <Stack
              direction="row"
              sx={{
                mt: 1,
                justifyContent: "flex-end",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              <ErrorOutlineIcon fontSize="small" color="error" />
              <Typography
                sx={{
                  lineHeight: 1,
                }}
                color="error"
                noWrap
                variant="caption"
              >
                Not Delivered
              </Typography>
            </Stack>
          )}
          {isSelected && (
            <Stack>
              <Link to={`/chat?clientId=${clientId}`}>
                <Button
                  size="small"
                  variant="outlined"
                  sx={{
                    backgroundColor: "white",
                    "&:hover": {
                      backgroundColor: (theme) => theme.palette.grey[200],
                    },
                  }}
                  color="primary"
                >
                  Go to chat
                </Button>
              </Link>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

Message.propTypes = {
  createdByAgent: PropTypes.object,
  createdByClient: PropTypes.object,
  createdAt: PropTypes.string,
  body: PropTypes.string,
  isDelivered: PropTypes.bool,
};

export default Message;

import PropTypes from "prop-types";
import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "src/context/UserContext";
import { useSocketEvent } from "src/utils/socket";
import { SOCKET_EVENTS } from "src/utils/constants";
import axios from "src/utils/axios";
import {
  Card,
  Box,
  CardHeader,
  Divider,
  Typography,
  Avatar,
  Tooltip,
  Stack,
} from "@mui/material";
import {
  Flag as FlagIcon,
  Medication as MedicationIcon,
  ForkRight as ForkRightIcon,
  PregnantWoman as PregnantWomanIcon,
} from "@mui/icons-material";
import {
  Timeline as MuiTimeline,
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
  TimelineContent,
  TimelineItem as MuiTimelineItem,
} from "@mui/lab";
import { toast } from "react-toastify";

import { STATIC_OPACITY, LOADING_OPACITY } from "src/utils/constants";
import BoxContainer from "src/components/common/Crm/BoxContainer";
import AtReStatus from "src/components/Client/ClientJourney/Status/AtReStatus";
import JourneyPathStatus from "src/components/Client/ClientJourney/Status/JourneyPathStatus";
import AtObgynStatus from "src/components/Client/ClientJourney/Status/AtObgynStatus";
import PregnantStatus from "src/components/Client/ClientJourney/Status/PregnantStatus";
import TimelineItemContainer from "./TimelineItemContainer";
import JourneyUpdate from "src/components/Client/ClientJourney/Update";
import Filter from "./Filter";
import ClientJourneyFormModal from "src/components/Client/ClientJourney/Update/Modal";

const getClientJourneyItems = async (clientId) => {
  try {
    const { data: clientJourneyItems } = await axios.get(
      `/api/clients/${clientId}/journey`
    );

    return clientJourneyItems;
  } catch (error) {
    throw new Error("Unable to fetch client journey items");
  }
};

const ClientJourney = ({ clientId, displayName, handleGetClient }) => {
  const { agent, isAgentAdmin } = useContext(UserContext);
  const [clientJourneyItems, setClientJourneyItems] = useState([]);
  const [currentJourneyPath, setCurrentJourneyPath] = useState(null);
  const [filteredJourneyItems, setFilteredJourneyItems] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [editId, setEditId] = useState(null);
  const [opacity, setOpacity] = useState(STATIC_OPACITY);

  const handleGetClientJourneyItems = async () => {
    try {
      const newClientJourneyItems = await getClientJourneyItems(clientId);
      setClientJourneyItems(newClientJourneyItems);
    } catch (error) {
      setHasError(true);
    }
  };

  const handleFilter = async (filters) => {
    const hasFilters = Object.keys(filters).some((key) => filters[key]);

    if (!hasFilters) {
      setFilteredJourneyItems(null);

      await handleGetClientJourneyItems();
      return;
    }

    // If there are Filters, filter the client journey items based on the filters:
    const filteredClientJourneyItems = clientJourneyItems.filter((item) => {
      const hasEvent = Boolean(item.journeyEvent);
      const hasComment = Boolean(item.comment);
      const hasWellbeingPoint = Boolean(item.wellbeingPoint);
      const hasTTCPath = Boolean(item.journeyPath?.label === "TTC");
      const hasEggFreezingPath = Boolean(
        item.journeyPath?.label === "Egg Freezing"
      );
      const isAtReStatus = item.type === "at-re-status";
      const isJourneyPathStatus = item.type === "journey-path-status";
      const isAtObgynStatus = item.type === "at-obgyn-status";

      return (
        (filters.hasEvent && hasEvent) ||
        (filters.hasComment && hasComment) ||
        (filters.hasWellbeingPoint && hasWellbeingPoint) ||
        (filters.hasTTCJourney && hasTTCPath) ||
        (filters.hasEggFreezingPath && hasEggFreezingPath) ||
        (filters.isAtReStatus && isAtReStatus) ||
        (filters.isJourneyPathStatus && isJourneyPathStatus) ||
        (filters.isAtObgynStatus && isAtObgynStatus)
      );
    });

    setFilteredJourneyItems(filteredClientJourneyItems);
  };

  const handleCreateNewClientJourneyItem = async (values, resetForm) => {
    try {
      setOpacity(LOADING_OPACITY);

      const payload = {
        ...values,
        agentId: agent.id,
      };

      await axios.post(`/api/clients/${clientId}/journey`, payload);

      resetForm();
    } catch (error) {
      setHasError(true);
      toast.error("Error creating a new timeline item");
      console.log("Error creating new timeline items =>", error);
    } finally {
      await handleGetClientJourneyItems();
      setOpacity(STATIC_OPACITY);
      await handleGetClient();
    }
  };

  const handleDeleteJourneyUpdate = async ({ id }) => {
    try {
      await axios.delete(`/api/clients/${clientId}/journey/${id}`);
    } catch (error) {
      toast.error("Unable to remove client journey item, please try again");
      console.log("Error: ", error);
    }
  };

  const handleDeleteStatus = async ({ statusId, statusType }) => {
    try {
      await axios.delete(
        `/api/clients/${clientId}/statuses/${statusType}/${statusId}`
      );
    } catch (error) {
      toast.error("Unable to remove client at re status, please try again");
      console.log("Error: ", error);
    }
  };

  const handleDeleteClientJourneyItem = async ({ itemId, itemType }) => {
    try {
      setOpacity(LOADING_OPACITY);

      const isStatus =
        itemType === "at-re-status" ||
        itemType === "at-obgyn-status" ||
        itemType === "pregnant-status" ||
        itemType === "journey-path-status";

      const isJourneyUpdate = itemType === "journey-update";

      if (isStatus) {
        await handleDeleteStatus({ statusId: itemId, statusType: itemType });
        return;
      }

      if (isJourneyUpdate) {
        await handleDeleteJourneyUpdate({ id: itemId });
        return;
      }
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      await handleGetClientJourneyItems();
      setOpacity(STATIC_OPACITY);
      await handleGetClient();
    }
  };

  const handleSetEditId = (itemId) => {
    setEditId(itemId);
  };

  const getHasEditActions = ({
    isFormUpdate,
    isAtReStatus,
    isAgentAdmin,
    isAgentUpdate,
    isWellbeingUpdate,
    isJoinedDate,
    isJourneyPathStatus,
    isAtObgynStatus,
    isPregnantStatus,
  }) => {
    // Wellbeing Points or Joined Dates are not editable
    if (isWellbeingUpdate || isJoinedDate) {
      return false;
    }

    // Allows only Admins to update form updates
    if (isFormUpdate && isAgentAdmin) {
      return true;
    }

    // Allows all agents to update agent updates
    if (
      isAtReStatus ||
      isAgentUpdate ||
      isJourneyPathStatus ||
      isAtObgynStatus ||
      isPregnantStatus
    ) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        setHasError(false);

        await handleGetClientJourneyItems();
      } catch (error) {
        setHasError(true);
        setClientJourneyItems([]);
        console.log("Error fetching client journey items =>", error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [clientId]);

  useSocketEvent({
    eventName: SOCKET_EVENTS.UPDATE_CLIENT_JOURNEY,
    onEvent: handleGetClientJourneyItems,
  });

  const journeyItems = filteredJourneyItems || clientJourneyItems;

  return (
    <Card elevation={1}>
      <CardHeader
        title="Client Journey"
        action={
          <Stack spacing={0.5} direction="row">
            <ClientJourneyFormModal
              onSubmit={handleCreateNewClientJourneyItem}
              clientId={clientId}
              currentJourneyPath={currentJourneyPath}
            />
            <Filter
              handleFilter={handleFilter}
              clientJourneyItems={clientJourneyItems}
            />
          </Stack>
        }
      />
      <Divider />

      <BoxContainer
        title="Client Timeline"
        isLoading={isLoading}
        hasError={hasError}
        errorMessage={"Unable to fetch Jouney"}
        opacity={opacity}
        hasChildren={Boolean(journeyItems.length)}
        sx={{
          minHeight: 300,
        }}
      >
        {!isLoading && Boolean(journeyItems.length) ? (
          <Box>
            <MuiTimeline>
              {journeyItems.map((item, index) => {
                const { type: itemType } = item;

                const isUpdate = item.type === "journey-update";
                const isJoinedDate = item.type === "joined-date";
                const isAtReStatus = item.type === "at-re-status";
                const isJourneyPathStatus = item.type === "journey-path-status";
                const isAtObgynStatus = item.type === "at-obgyn-status";
                const isPregnantStatus = item.type === "pregnant-status";

                const isAgentUpdate = isUpdate && item.createdBy === "agent";
                const isFormUpdate = isUpdate && !isAgentUpdate;
                const isWellbeingUpdate = Boolean(item.wellbeingPoint);

                const hasEditActions = getHasEditActions({
                  isAtReStatus,
                  isFormUpdate,
                  isAgentAdmin,
                  isAgentUpdate,
                  isWellbeingUpdate,
                  isJoinedDate,
                  isJourneyPathStatus,
                  isAtObgynStatus,
                  isPregnantStatus,
                });

                return (
                  <MuiTimelineItem
                    position="right"
                    key={index}
                    sx={{
                      "&::before": {
                        flex: 0,
                        padding: 0,
                      },
                    }}
                    style={{ opacity }}
                  >
                    <TimelineSeparator
                      sx={{
                        span: {
                          my: 0.5,
                        },
                        "& .MuiTimelineConnector-root": {
                          backgroundColor: (theme) => theme.palette.grey20,
                          width: "1px",
                        },

                        "& .MuiTimelineDot-root": {
                          padding: 0,
                          width: "32px",
                          height: "32px",
                          justifyContent: "center",
                          alignItems: "center",
                          "& svg": {
                            width: 18,
                          },
                        },
                      }}
                    >
                      {isUpdate && (
                        <TimelineDot color="primary">
                          <Tooltip
                            placement="top"
                            arrow={true}
                            variant="outlined"
                            title={`Created by ${
                              item.createdBy === "client"
                                ? displayName
                                : `${item.agent?.firstName} ${item.agent?.lastName}`
                            }`}
                          >
                            <Avatar src={item.agent?.avatarLink}>
                              {isAgentUpdate
                                ? item.agent?.firstName[0] +
                                  item.agent?.lastName[0]
                                : displayName[0]}
                            </Avatar>
                          </Tooltip>
                        </TimelineDot>
                      )}

                      {isAtReStatus && (
                        <TimelineDot color="primary" variant="outlined">
                          <Tooltip
                            placement="top"
                            arrow={true}
                            title={`RE Status Update`}
                          >
                            <MedicationIcon />
                          </Tooltip>
                        </TimelineDot>
                      )}

                      {isJourneyPathStatus && (
                        <TimelineDot color="primary" variant="outlined">
                          <Tooltip
                            placement="top"
                            arrow={true}
                            title={`Journey Path Status`}
                          >
                            <ForkRightIcon />
                          </Tooltip>
                        </TimelineDot>
                      )}

                      {isAtObgynStatus && (
                        <TimelineDot color="primary" variant="outlined">
                          <Tooltip
                            placement="top"
                            arrow={true}
                            title={`OBGYN Status Update`}
                          >
                            <MedicationIcon />
                          </Tooltip>
                        </TimelineDot>
                      )}

                      {isPregnantStatus && (
                        <TimelineDot color="primary" variant="outlined">
                          <Tooltip
                            placement="top"
                            arrow={true}
                            title={`Pregnant Status Update`}
                          >
                            <PregnantWomanIcon />
                          </Tooltip>
                        </TimelineDot>
                      )}

                      {isJoinedDate && (
                        <TimelineDot color="primary">
                          <FlagIcon />
                        </TimelineDot>
                      )}

                      {index + 1 < journeyItems.length ? (
                        <TimelineConnector />
                      ) : null}
                    </TimelineSeparator>
                    <TimelineContent>
                      <TimelineItemContainer
                        hasDeleteAction={hasEditActions}
                        // Journey updates have a createdAtDate because they are created manually
                        // Status items have a createdAt because they are created automatically
                        // This is display only so it doesn't matter which one is used
                        createdAt={item.createdAt}
                        index={index}
                        currentEditId={editId}
                        itemId={item.id}
                        itemType={itemType}
                        handleDeleteItem={handleDeleteClientJourneyItem}
                        handleSetEditId={handleSetEditId}
                      >
                        {isUpdate && (
                          <JourneyUpdate
                            item={item}
                            itemType={itemType}
                            index={index}
                            onCancel={() => handleSetEditId(null)}
                            clientId={clientId}
                            currentJourneyPath={currentJourneyPath}
                          />
                        )}

                        {isAtReStatus && (
                          <AtReStatus
                            status={item.status}
                            notAtReReasons={item.notAtReReasons}
                          />
                        )}

                        {isJourneyPathStatus && (
                          <JourneyPathStatus status={item.status} />
                        )}

                        {isAtObgynStatus && (
                          <AtObgynStatus status={item.status} />
                        )}

                        {isPregnantStatus && (
                          <PregnantStatus status={item.status} />
                        )}

                        {isJoinedDate && (
                          <Typography sx={{ width: "100%" }} variant="body2">
                            Joined Fertility Outreach
                          </Typography>
                        )}
                      </TimelineItemContainer>
                    </TimelineContent>
                  </MuiTimelineItem>
                );
              })}
            </MuiTimeline>
          </Box>
        ) : null}
      </BoxContainer>
    </Card>
  );
};

ClientJourney.propTypes = {
  clientId: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
};

export default ClientJourney;

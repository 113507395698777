import { useContext, useEffect, useState } from "react";
import { Typography, Checkbox, Grid } from "@mui/material";
import { OptionContext } from "src/context/OptionContext";
import MultiSelect from "src/components/common/MultiSelect";
import SingeSelectAutocomplete from "src/components/common/SingleSelectAutocomplete";
import DateRangePicker from "src/components/common/DateRangePicker";
import { DEFAULT_CLIENTS_FILTERS } from "src/utils/constants";

const DefaultClientsFilters = ({
  values: initialValues = {},
  handleSetFieldValue,
}) => {
  const [values, setValues] = useState(DEFAULT_CLIENTS_FILTERS);

  const {
    atReStatusOptions,
    journeyPathStatusOptions,
    pregnantStatusOptions,
    atObgynStatusOptions,
    hasInsuranceOptions,
    priorDiagnosisOptions,
    journeyUpdateEventOptions,
    engagementOptions,
    ageRangeOptions,
    tagOptions,
  } = useContext(OptionContext);

  const singleSelectFilters = [
    {
      id: "has-insurance",
      name: "hasInsurance",
      label: "Has Insurance",
      options: hasInsuranceOptions,
    },
    {
      id: "intake-form-completion",
      name: "intakeFormCompletion",
      label: "Intake Form Completion",
      options: [
        {
          id: "yes",
          label: "Yes",
        },
        {
          id: "no",
          label: "No",
        },
      ],
    },
    {
      id: "follow-up-form-completion",
      name: "followUpFormCompletion",
      label: "Follow Up Form Completion",
      options: [
        {
          id: "yes",
          label: "Yes",
        },
        {
          id: "no",
          label: "No",
        },
      ],
    },
  ];

  const multiSelectFilters = [
    {
      id: "engagements",
      name: "engagements",
      label: "Engagements",
      options: engagementOptions,
    },
    {
      id: "age-ranges",
      name: "ageRanges",
      label: "Age Ranges",
      options: ageRangeOptions,
    },
    {
      id: "prior-diagnoses",
      name: "priorDiagnosises",
      label: "Prior Diagnoses",
      options: priorDiagnosisOptions,
    },
    {
      id: "tags",
      name: "tags",
      label: "Tags",
      options: tagOptions,
    },
    {
      id: "journey-update-events",
      name: "journeyUpdateEvents",
      label: "Journey Update Events",
      options: journeyUpdateEventOptions,
    },
  ];

  const statusFilters = [
    {
      id: "at-re-status",
      name: "currentAtReStatus",
      label: "At RE Status",
      options: atReStatusOptions,
    },
    {
      id: "journey-path-status",
      name: "currentJourneyPathStatus",
      label: "Journey Path Status",
      options: journeyPathStatusOptions,
    },
    {
      id: "pregnant-status",
      name: "currentPregnantStatus",
      label: "Pregnant Status",
      options: pregnantStatusOptions,
    },
    {
      id: "at-obgyn-status",
      name: "currentAtObgynStatus",
      label: "At OBGYN Status",
      options: atObgynStatusOptions,
    },
  ];

  const checkboxFilters = [
    {
      id: "is-active",
      name: "isActive",
      label: "Active Clients",
    },
    {
      id: "is-qualified",
      name: "isQualified",
      label: "Is Qualified",
    },
    {
      id: "has-bmi-under-40",
      name: "hasBmiUnder40",
      label: "BMI Under 40",
    },
    {
      id: "has-virtual-session",
      name: "hasVirtualSession",
      label: "Has Virtual Session",
    },
    {
      id: "has-booked-virtual-session",
      name: "hasBookedVirtualSession",
      label: "Booked Virtual Session",
    },
    {
      id: "has-completed-virtual-session",
      name: "hasCompletedVirtualSession",
      label: "Completed Virtual Session",
    },
    {
      id: "has-not-receieved-message-from-coach",
      name: "hasNotReceivedCoachMessage",
      label: "Not Received Coach Message",
    },
  ];

  useEffect(() => {
    if (initialValues) {
      setValues((prevValues) => ({
        ...prevValues,
        ...initialValues,
      }));
    }
  }, [initialValues]);

  return (
    <>
      <Grid container spacing={1}>
        <>
          {singleSelectFilters.map((filter) => (
            <Grid item xs={12} md={6} key={filter.id}>
              <SingeSelectAutocomplete
                key={filter.id}
                id={filter.id}
                label={filter.label}
                options={filter.options}
                value={values[filter.name]?.id || null}
                onChange={(value, newValue) => {
                  handleSetFieldValue(filter.name, newValue);
                }}
              />
            </Grid>
          ))}
          {multiSelectFilters.map((filter) => (
            <Grid item xs={12} md={6} key={filter.id}>
              <MultiSelect
                key={filter.id}
                id={filter.id}
                label={filter.label}
                options={filter.options}
                values={values[filter.name]}
                setValues={(values) => {
                  handleSetFieldValue(filter.name, values);
                }}
                limitTags={2}
              />
            </Grid>
          ))}
        </>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6">Current Status</Typography>
        </Grid>
        {statusFilters.map((filter) => (
          <Grid item xs={12} md={6} key={filter.id}>
            <SingeSelectAutocomplete
              key={filter.id}
              id={filter.id}
              label={filter.label}
              options={filter.options}
              value={values[filter.name]?.id || null}
              onChange={(value, newValue) => {
                handleSetFieldValue(filter.name, newValue);
              }}
            />
          </Grid>
        ))}
      </Grid>
      <Grid container>
        {checkboxFilters.map((checkbox) => (
          <Grid
            key={checkbox.id}
            item
            container
            alignItems="center"
            justifyContent="space-between"
            xs={12}
            lg={6}
          >
            <Typography htmlFor={checkbox.id} variant="body1">
              {checkbox.label}
            </Typography>
            <Checkbox
              id={checkbox.id}
              label={checkbox.label}
              color="primary"
              checked={values[checkbox.name] || false}
              fontSize="large"
              onChange={(e) => {
                handleSetFieldValue(checkbox.name, e.target.checked);
              }}
            />
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DateRangePicker
            label="Sign Up Date"
            startDate={{
              label: "From",
              value: values.signUpDateFrom,
              handleChange: (value) => {
                handleSetFieldValue("signUpDateFrom", value);
              },
            }}
            endDate={{
              label: "To",
              value: values.signUpDateTo,
              handleChange: (value) => {
                handleSetFieldValue("signUpDateTo", value);
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DefaultClientsFilters;

import propTypes from "prop-types";
import { useEffect, useState } from "react";
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Stack,
  Alert,
} from "@mui/material";
import React from "react";
import ToolTip from "src/components/common/ToolTip";
import Loader from "src/components/common/Loader";

/**
 *  A dropdown component that allows single selections.
 * @param {string} id - The id of the dropdown.
 * @param {string} label - The label and placeholder of the dropdown.
 *  @param {number} value - The selected value. (id)
 * @param {object} options - The options to display in the dropdown.
 * @param {Function} onChange - The function to set the selected value. (id)
 * @param {string} variant - The variant of the dropdown. (outlined or standard)
 * @param {object} rest - The rest of the props.
 *
 */
const SingleSelect = ({
  id,
  label,
  value,
  options,
  onChange,
  variant = "outlined",
  hasError: initialHasError = false,
  hasWhiteBackground,
  onOpen,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(initialHasError);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setHasError(initialHasError);
  }, [initialHasError]);
  return (
    <FormControl fullWidth variant={variant} sx={{ height: "100%" }}>
      <InputLabel id={`${id}-label`} htmlFor={id}>
        {label}
      </InputLabel>
      <Select
        id={id}
        label={label}
        labelId={`${id}-label`}
        variant={variant}
        value={value || ""}
        onChange={(e) => {
          const object = options.find((option) => option.id === e.target.value);
          onChange(e.target.value, object);
        }}
        onOpen={async () => {
          if (onOpen) {
            try {
              setIsLoading(true);
              setHasError(false);
              setErrorMessage("");
              await onOpen();
            } catch (error) {
              setHasError(true);
              setErrorMessage(error.message || `Failed to load ${id} options`);
            } finally {
              setIsLoading(false);
            }
          }
        }}
        error={hasError}
        {...rest}
        sx={{
          backgroundColor: hasWhiteBackground ? "white" : "inherit",
          ...rest.sx,
          ...(hasError && {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "red",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "red",
            },
          }),
        }}
      >
        {isLoading && (
          <Stack alignItems="center">
            <Loader size="16px" />
          </Stack>
        )}
        {hasError && errorMessage && (
          <Alert severity="error">{errorMessage}</Alert>
        )}
        <MenuItem value={null}>
          <span>-</span>
        </MenuItem>
        {options?.map((option) => {
          const keyOrValue = option.id || option.value;
          return (
            <MenuItem key={keyOrValue} value={keyOrValue}>
              <ToolTip title={option?.label || ""} placement="top">
                <span>{option.shortLabel || option.label}</span>
              </ToolTip>
            </MenuItem>
          );
        })}

        {!options?.length && !isLoading && !hasError && (
          <Alert severity="info">No {id} options found</Alert>
        )}
      </Select>
    </FormControl>
  );
};

export default SingleSelect;

SingleSelect.propTypes = {
  id: propTypes.string.isRequired,
  label: propTypes.string,
  value: propTypes.oneOfType([
    propTypes.string,
    propTypes.number,
    propTypes.object,
  ]),
  options: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.oneOfType([propTypes.string, propTypes.number]),
      label: propTypes.string,
      shortLabel: propTypes.string,
    })
  ),
  onChange: propTypes.func.isRequired,
  variant: propTypes.oneOf(["outlined", "standard", "filled"]),
  hasError: propTypes.bool,
  rest: propTypes.any,
};

import { Stack } from "@mui/material";
import Messages from "src/components/Chat/Main/Messages";
import Composer from "src/components/Chat/Main/Composer";
import Details from "src/components/Chat/Main/Details";

const Main = () => {
  return (
    <Stack direction="row" flex={1} height="100%">
      <Stack direction="column" flex={1} height="100%">
        <Messages />
        <Composer />
      </Stack>
      <Details />
    </Stack>
  );
};

export default Main;

import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, Stack } from "@mui/material";
import moment from "moment";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const SearchResult = ({
  createdByAgent,
  createdByClient,
  id,
  isSelected = false,
  handleClick,
  createdAt,
  body,
  textQuery,
}) => {
  const createdBy = createdByAgent || createdByClient || {};

  const formattedBody = textQuery
    ? body.replace(
        new RegExp(textQuery, "gi"),
        (match) => `<mark>${match}</mark>`
      )
    : body;

  const indexOfMark = formattedBody.indexOf("<mark>");

  const previewBody =
    indexOfMark > 0 ? `... ${formattedBody.slice(indexOfMark)}` : formattedBody;

  return (
    <Box
      sx={{
        px: 1,
        py: 0.5,
        flexDirection: "column",
        alignItems: "flex-start",
        backgroundColor: (theme) =>
          isSelected ? theme.palette.primary.main : "transparent",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: (theme) =>
            isSelected ? theme.palette.primary.light : theme.palette.grey[200],
        },
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      }}
      onClick={() => handleClick(id)}
    >
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", width: "100%" }}
      >
        <Typography
          variant="body2"
          gutterBottom
          sx={{ color: isSelected ? "white" : "text.primary" }}
        >
          {createdBy.displayName ||
            `${createdBy.firstName} ${createdBy.lastName}`}
        </Typography>
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{ color: isSelected ? "white" : "text.secondary" }}
        >
          {moment(createdAt).format("L")}
        </Typography>
      </Stack>
      <Box
        sx={{
          color: isSelected ? "white" : "text.secondary",
          fontSize: (theme) => theme.typography.body2.fontSize,
          wordBreak: "break-word",
          width: "100%",
          height: "2.75rem",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 3,
          WebkitBoxOrient: "vertical",
        }}
      >
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{previewBody}</ReactMarkdown>
      </Box>
    </Box>
  );
};

SearchResult.propTypes = {
  isActive: PropTypes.bool,
  onSelect: PropTypes.func,
  messageId: PropTypes.string,
  createdAt: PropTypes.string,
  body: PropTypes.string,
  createdByAgent: PropTypes.object,
  createdByClient: PropTypes.object,
};

export default SearchResult;

export const keys = {
  messages: "messages",
  recipient: "recipient",
  client: "client",
  searchResults: "search-results",
  threads: "threads",
  campaigns: "campaigns",
  campaignFilters: "campaign-filters",
};

export const keyFactories = {
  messages: ({ clientId = null, messageId = null }) => [
    keys.messages,
    clientId,
    messageId,
  ],
  recipient: (clientId) => [keys.recipient, clientId],
  client: (clientId) => [keys.client, clientId],
  searchResults: ({ textQuery, userType }) => [
    keys.searchResults,
    {
      filters: {
        textQuery,
        userType,
      },
    },
  ],
  threads: (threadFlags = null) => [keys.threads, { filters: threadFlags }],
};

import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Tooltip from "src/components/common/ToolTip";

const setValueOrNa = (value) => {
  return value ? value : "-";
};

const DisplayText = ({
  primary: label,
  secondary: initialSecondary,
  editor,
  sx,
}) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(initialSecondary);
  }, [initialSecondary]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      height="28px"
      {...sx}
    >
      {label && (
        <Stack
          direction="row"
          alignItems="center"
          spacing={0.5}
          sx={{ maxWidth: "60%" }}
        >
          <Typography
            fontWeight={500}
            variant="caption"
            sx={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {label}
          </Typography>
          {editor && editor({ setDisplayTextValue: setValue })}
        </Stack>
      )}
      <Stack sx={{ maxWidth: "40%" }}>
        <Tooltip arrow title={value === "-" ? "" : value} placement="top-start">
          <Typography
            noWrap
            variant="body2"
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "noWrap",
            }}
          >
            {setValueOrNa(value)}
          </Typography>
        </Tooltip>
      </Stack>
    </Stack>
  );
};

export default DisplayText;

import React, { forwardRef } from "react";
import { default as ReactPhoneInput } from "react-phone-number-input";
import { makeStyles } from "@mui/styles";
import { TextField } from "@mui/material";
import TextInput from "../TextInput";

const useStyles = makeStyles((theme) => ({
  phoneInput: {
    width: "100%",
  },
}));

const textInput = forwardRef((props, ref) => {
  return <TextInput {...props} inputRef={ref} />;
});

const textField = forwardRef((props, ref) => {
  return <TextField {...props} inputRef={ref} />;
});

const PhoneInput = ({
  value,
  onChange,
  inputComponent,
  fullWidth,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <ReactPhoneInput
      className={fullWidth ? classes.phoneInput : ""}
      value={value}
      inputComponent={
        inputComponent === "mui-textfield" ? textField : textInput
      }
      country="US"
      defaultCountry="US"
      onChange={(value) => onChange(value || "")}
      fullWidth={fullWidth}
      {...rest}
    />
  );
};

export default PhoneInput;
